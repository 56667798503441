import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Table,
} from "antd";

import React, { useEffect, useState } from "react";
import ImageError from "../../MyComponents/ImageError";
import axios from "axios";
import { httpClient } from "../../util/Api";

import RenderList from "../../MyComponents/RenderList";
import FormatMoment from "../../MyComponents/FormatMoment";

export default function Home() {
  const source = axios.CancelToken.source();
  const [loading, setLoading] = useState(false);
  const [projectNew, setProjectNew] = useState(null);
  const [banners, setBanners] = useState(null);
  const [modal, setModal] = useState({
    visible: false,
    loading: false,
    data: null,
    selectedRowKeys: [],
    type: "",
    search: "",
    total: 0,
    page: 1,
    limit: 10,
  });

  const fetchData = () => {
    setLoading(true);
    httpClient
      .get(`/admin/home`, { cancelToken: source.token })
      .then((res) => {
        setLoading(false);
        setBanners(res.data.banner);
        setProjectNew(res.data["project & news"]);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const handleonChangeType = () => {
    setModal({
      ...modal,
      loading: true,
      data: null,
      selectedRowKeys: [],
      mtotal: 0,
    });
    const { page, limit, search, type } = modal;
    httpClient
      .get(`/admin/home/${type}`, {
        params: { offset: (page - 1) * limit, limit, search, type },
        cancelToken: source.token,
      })
      .then((res) => {
        setModal({
          ...modal,
          loading: false,
          data: res.data.items,
          total: res.data.total,
        });
      })
      .catch((err) => {
        setModal({ ...modal, loading: false });
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const onFinish = () => {
    if (modal.selectedRowKeys.length === 0) {
      return Modal.warning({
        title: "คุณยังไม่เลือกรายการ",
        content: "กรุณาเลือกรายการที่ต้องการเพิ่ม",
        okText: "ตกลง",
      });
    }

    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกข้อมูลหรือไม่?",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          httpClient
            .post(
              `/admin/home`,
              {
                type: modal.type,
                selectedRowKeys: modal.selectedRowKeys,
              },
              {
                cancelToken: source.token,
              }
            )
            .then((res) => {
              resolve(res);
            })
            .catch((err) =>
              reject(
                err?.response?.data ? err?.response?.data : err?.message || err
              )
            );
        })
          .then((value) => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                setModal({
                  ...modal,
                  visible: false,
                  data: null,
                  selectedRowKeys: [],
                  search: "",
                  total: 0,
                  page: 1,
                });
                fetchData();
              },
            });
          })
          .catch((reason) => {
            message.error(reason);
          });
      },
    });
  };

  const handleOnSort = (group_key, items) => {
    setLoading(true);
    httpClient
      .post(
        `/admin/home/sorting`,
        {
          group_key,
          items,
        },
        {
          cancelToken: source.token,
        }
      )
      .then(() => {
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleOnDelete = (item) => {
    setLoading(true);
    httpClient
      .post(
        "/admin/home/delete",
        {
          ref_id: item.ref_id,
          ref_type: item.ref_type,
          group_key: item.group_key,
        },
        {
          cancelToken: source.token,
        }
      )
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        message.error(
          err?.response?.data ? err?.response?.data : err?.message || err
        );
      });
  };

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, []);

  useEffect(() => {
    if (modal.visible) {
      handleonChangeType();
    }
  }, [modal.type, modal.visible, modal.page, modal.search]);

  return (
    <>
      <Spin spinning={loading}>
        <Card
          title="โฆษณาที่แสดง"
          className="mb-4"
          extra={
            <Button
              type="primary"
              style={{ margin: 0 }}
              onClick={() => {
                setModal({
                  ...modal,
                  visible: true,
                  data: null,
                  selectedRowKeys: [],
                  search: "",
                  total: 0,
                  page: 1,
                  type: "banner",
                });
              }}
            >
              เลือกโฆษณา
            </Button>
          }
        >
          {banners && (
            <RenderList
              dataSource={banners ? banners : []}
              onDelete={handleOnDelete}
              onSort={handleOnSort}
            />
          )}
        </Card>

        <Card
          title="โครงการ และ ข่าวที่แสดง"
          className="mb-4"
          extra={
            <>
              <Button
                type="primary"
                style={{ margin: "0 15px 0 0" }}
                onClick={() => {
                  setModal({
                    ...modal,
                    visible: true,
                    data: null,
                    selectedRowKeys: [],
                    search: "",
                    total: 0,
                    page: 1,
                    type: "project",
                  });
                }}
              >
                เลือกโครงการ
              </Button>
              <Button
                type="primary"
                style={{ margin: 0 }}
                onClick={() => {
                  setModal({
                    ...modal,
                    visible: true,
                    data: null,
                    selectedRowKeys: [],
                    search: "",
                    total: 0,
                    page: 1,
                    type: "news",
                  });
                }}
              >
                เลือกข่าว
              </Button>
            </>
          }
        >
          {projectNew && (
            <RenderList
              dataSource={projectNew}
              onDelete={handleOnDelete}
              onSort={handleOnSort}
            />
          )}
        </Card>
      </Spin>
      <Modal
        visible={modal.visible}
        title="เลือก"
        okText="ตกลง"
        cancelText="ยกเลิก"
        onCancel={() => {
          setModal({
            ...modal,
            visible: false,
            data: null,
            selectedRowKeys: [],
            search: "",
            total: 0,
            page: 1,
          });
        }}
        onOk={onFinish}
        maskClosable={false}
        width={800}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Input.Search
              style={{ width: "250px" }}
              onSearch={(event) => {
                setModal({ ...modal, search: event || null, page: 1 });
              }}
              allowClear
            />
            <Table
              pagination={{
                size: "default",
                current: modal.page,
                pageSize: modal.limit,
                total: modal.total,
                showLessItems: true,
                showSizeChanger: false,
                showTotal: (total, range) =>
                  `${range[0]} - ${range[1]} of ${total}`,
                onChange: (current, pageSize) => {
                  setModal({ ...modal, page: current });
                },
              }}
              bordered
              dataSource={modal.data ? modal.data : []}
              rowSelection={{
                onChange: (selectedRowKeys) => {
                  setModal({ ...modal, selectedRowKeys: selectedRowKeys });
                },
                selectedRowKeys: modal.selectedRowKeys,
              }}
              rowKey="id"
              loading={modal.loading}
            >
              <Table.Column
                title="รูป"
                dataIndex="cover_image"
                align="center"
                width="120px"
                render={(text, record) => <ImageError src={text?.url} />}
              />
              <Table.Column title="ชื่อ (TH)" dataIndex="name_th" />
              <Table.Column title="ชื่อ (EN)" dataIndex="name_en" />
              <Table.Column
                title="แก้ไขล่าสุด"
                dataIndex="update_date"
                align="center"
                className="whitespace-nowrap"
                render={(text) => text && <FormatMoment text={text} />}
              />
            </Table>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
