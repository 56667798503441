import {
  UploadOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Upload,
} from "antd";
import ImageError from "../../../MyComponents/ImageError";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../util/Api";
import { Link } from "react-router-dom";

const CM = () => {
  const source = axios.CancelToken.source();
  const [formRef, setFormRef] = useState(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState({
    project_type: "h",
  });
  const [saving, setSaving] = useState(false);
  const [modal, setModal] = useState({
    visible: false,
    data: null,
    selectedKey: null,
  });
  const [images, setImages] = useState(null);

  const handleUploadDummy = ({ file, onSuccess }) => {
    let formData = new FormData();
    formData.append("data", file);
    formData.append(
      "type",
      JSON.stringify({ subject: "project-cm", product: "customer-portal-web" })
    );

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    httpClient
      .post(`${process.env.REACT_APP_URL_API}/files`, formData, config)
      .then((res) => {
        onSuccess(res.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const uploadProps = {
    name: "file",
    customRequest: handleUploadDummy,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }

      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setImages(info.file.response.id);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const fetchData = () => {
    setLoading(true);
    httpClient
      .get("/admin/setting/cm", {
        params: search,
        cancelToken: source.token,
      })
      .then((res) => {
        setData(res.data.items);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const onFinish = (values) => {
    setSaving(true);
    httpClient
      .post(
        `/admin/setting/cm${values.id ? `/${values.id}` : ""}`,
        {
          ...values,
          image_id: images,
        },
        { cancelToken: source.token }
      )
      .then((res) => {
        setSaving(false);
        fetchData();
        setModal({ ...modal, visible: false, data: null, selectedKey: null });
      })
      .catch((err) => {
        setSaving(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const handleClickDelete = (id) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการลบรายการนี้หรือไม่",
      okText: "ลบ",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          httpClient
            .post(
              `/admin/setting/cm/${id}/delete`,
              {},
              { cancelToken: source.token }
            )
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err?.response?.data ? err?.response?.data : err?.message || err
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ลบรายการเรียบร้อย",
              okText: "ปิด",
              onOk() {
                fetchData();
              },
            });
          })
          .catch((reason) => {
            Modal.error({ title: "ผิดพลาด", content: reason, okText: "ปิด" });
          });
      },
    });
  };

  const handleClickSort = (type, index) => {
    let newData = [...data];
    let from = newData[index];
    let to = newData[type === "up" ? index - 1 : index + 1];
    newData[index] = to;
    newData[type === "up" ? index - 1 : index + 1] = from;

    httpClient
      .post("/admin/setting/cm/sorting", newData, { cancelToken: source.token })
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        message.error(
          err?.response?.data ? err?.response?.data : err?.message || err
        );
      });
  };

  useEffect(() => {
    if (modal.visible && formRef) {
      formRef.resetFields();
      formRef.setFieldsValue({
        ...modal.data,
      });
    }
  }, [modal.visible, formRef]);

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [search.project_type]);

  return (
    <Spin spinning={loading} tip="กำลังโหลดข้อมูล">
      <Row gutter={16}>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#ffffff",
              padding: "16px 24px 16px 24px",
              border: "1px solid #f0f0f0",
              borderRadius: "6px 6px 0 0",
            }}
          >
            <span>เมนูแจ้งซ่อม</span>
            <Button
              type="primary"
              style={{ margin: 0 }}
              onClick={() => {
                setModal({
                  ...modal,
                  visible: true,
                  data: null,
                  selectedKey: null,
                });
              }}
            >
              เพิ่ม
            </Button>
          </div>

          <Card>
            <Form layout="inline">
              <Form.Item label="ประเภทโครงการ">
                <Select
                  style={{ width: "150px" }}
                  value={search.project_type}
                  onChange={(value) => {
                    setSearch({ ...search, project_type: value });
                  }}
                >
                  <Select.Option value="h">แนวราบ</Select.Option>
                  <Select.Option value="c">แนวสูง</Select.Option>
                </Select>
              </Form.Item>
            </Form>
            <Table dataSource={data} pagination={false} rowKey="id">
              <Table.Column
                title="รูป"
                align="center"
                dataIndex="image_id"
                width="120px"
                render={(text, record) => (
                  <Link
                    onClick={() => {
                      setModal({
                        ...modal,
                        visible: true,
                        data: record,
                        selectedKey: record.id,
                      });
                    }}
                  >
                    <ImageError
                      onClick={() => {
                        setModal({
                          ...modal,
                          visible: true,
                          data: record,
                          selectedKey: record.id,
                        });
                      }}
                      src={`${process.env.REACT_APP_URL_API}/images/${text}?w=200`}
                    />
                  </Link>
                )}
              />
              <Table.Column title="ชื่อ (TH)" dataIndex="name_th" />
              <Table.Column title="ชื่อ (EN)" dataIndex="name_en" />
              <Table.Column
                title="Keyword"
                dataIndex="defect_type"
                align="center"
              />
              <Table.Column
                align="center"
                dataIndex="id"
                render={(text, record, index) => (
                  <Space size="small">
                    <Button
                      size="small"
                      type="link"
                      disabled={index === 0}
                      onClick={() => handleClickSort("up", index)}
                    >
                      <ArrowUpOutlined />
                    </Button>
                    <Button
                      size="small"
                      type="link"
                      disabled={index + 1 === data?.length}
                      onClick={() => handleClickSort("down", index)}
                    >
                      <ArrowDownOutlined />
                    </Button>
                    <Button
                      size="small"
                      type="link"
                      onClick={() => {
                        setModal({
                          ...modal,
                          visible: true,
                          data: record,
                          selectedKey: record.id,
                        });
                      }}
                    >
                      แก้ไข
                    </Button>
                    <Button
                      size="small"
                      type="link"
                      onClick={() => handleClickDelete(text)}
                    >
                      ลบ
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </Card>
        </Col>
      </Row>

      <Modal
        visible={modal.visible}
        title="เมนู"
        okText="บันทึก"
        cancelText="ยกเลิก"
        onOk={() => formRef.submit()}
        onCancel={() => {
          setModal({ ...modal, visible: false, data: null, selectedKey: null });
        }}
        confirmLoading={saving}
        maskClosable={false}
      >
        <Form
          wrapperCol={{ md: 16 }}
          labelCol={{ md: 8 }}
          ref={setFormRef}
          onFinish={onFinish}
        >
          <Form.Item
            label="ประเภทโครงการ"
            name="project_type"
            rules={[{ required: true, message: "กรุณาเลือกประเภทโครงการ" }]}
          >
            <Radio.Group>
              <Radio value="h">แนวราบ</Radio>
              <Radio value="c">แนวสูง</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="ชื่อ (TH)"
            name="name_th"
            rules={[{ required: true, message: "กรุณากรอกชื่อ" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="ชื่อ (EN)" name="name_en">
            <Input />
          </Form.Item>
          <Form.Item
            label="Keyword"
            name="defect_type"
            rules={[{ required: true, message: "กรุณากรอก Keyword." }]}
          >
            <Input placeholder="Keyword" />
          </Form.Item>
          <Form.Item
            label="รูป"
            name="image"
            rules={[
              {
                required: modal.selectedKey == null ? true : false,
                message: "กรุณาอัพโหลดรูปภาพ",
              },
            ]}
            getValueFromEvent={normFile}
            valuePropName="fileList"
          >
            <Upload {...uploadProps} multiple={false} listType="picture">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            <p className="text-red-500">*ขนาดรูปที่แนะนำไม่เกิน 4 mb</p>
          </Form.Item>
          <Form.Item name="id">
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

export default CM;
