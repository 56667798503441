import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { httpClient } from "../../../util/Api";
import { Button, Card, message, Modal, Space, Table } from "antd";
import FormatMoment from "../../../MyComponents/FormatMoment";

const ListCustomerGroup = () => {
  const source = axios.CancelToken.source();
  const limit = 10;
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const fetchData = () => {
    setLoading(true);
    httpClient
      .get(`/admin/customers/group`, {
        params: { limit, offset: (page - 1) * limit },
        cancelToken: source.token,
      })
      .then((res) => {
        setLoading(false);
        setDataSource(res.data.data || []);
        setTotal(res.data.total || 0);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const handleClickDelete = (id) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการลบข้อมูลหรือไม่",
      okText: "ลบ",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          httpClient
            .post(`/admin/customers/group/${id}/delete`, {
              cancelToken: source.token,
            })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err?.response?.data ? err?.response?.data : err?.message || err
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ลบข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                fetchData();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [page]);

  return (
    <Card
      title="กลุ่มลูกค้า"
      extra={
        <Link to={`/customer-group/detail/0`}>
          <Button type="primary" style={{ margin: 0 }}>
            เพิ่ม
          </Button>
        </Link>
      }
    >
      <Table
        loading={loading}
        rowKey="id"
        dataSource={dataSource}
        pagination={{
          size: "default",
          current: page,
          total: total,
          pageSize: limit,
          showLessItems: true,
          showSizeChanger: false,
          showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`,
          onChange: (page) => setPage(page),
        }}
      >
        <Table.Column title="ชื่อ" dataIndex="name" />
        <Table.Column title="รายละเอียด" dataIndex="description" />
        <Table.Column
          title="แก้ไขล่าสุด"
          dataIndex="update_date"
          align="center"
          render={(text) => text && <FormatMoment text={text} />}
        />
        <Table.Column
          dataIndex="id"
          align="center"
          render={(text) => (
            <Space size="middle">
              <Link to={`/customer-group/detail/${text}`}>
                <Button style={{ margin: 0 }} type="link">
                  แก้ไข
                </Button>
              </Link>
              <Button
                style={{ margin: 0 }}
                type="link"
                onClick={() => handleClickDelete(text)}
              >
                ลบ
              </Button>
            </Space>
          )}
        />
      </Table>
    </Card>
  );
};

export default ListCustomerGroup;
