/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import noImg from "../assets/img/NoData.png";

const ImgCard = ({
  img_url = null,
  actions = null,
  title = null,
  description = null,
  link_to = null,
  ...props
}) => {
  return (
    <Card
      {...props}
      cover={
        link_to ? (
          <Link to={link_to}>
            <img
              alt="example"
              src={img_url}
              onError={(event) => {
                event.target.onerror = null;
                event.target.src = noImg;
              }}
              className="w-full h-auto object-cover"
              style={{ height: 250 }}
            />
          </Link>
        ) : (
          <img
            alt="example"
            src={img_url}
            onError={(event) => {
              event.target.onerror = null;
              event.target.src = noImg;
            }}
            className="w-full h-auto object-cover"
            style={{ height: 250 }}
          />
        )
      }
    >
      <Card.Meta title={title} description={description} />
      {props.children}
    </Card>
  );
};

export default ImgCard;
