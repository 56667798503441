import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Tabs,
  Table,
  Select,
} from "antd";
import {
  SaveOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { httpClient } from "../../../util/Api";
import { Link } from "react-router-dom";
import QuillEditor from "../../../MyComponents/QuillEditor";

const CompanyProfile = ({ match }) => {
  const source = axios.CancelToken.source();
  const [syncing, setSyncing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [projectCount, setProjectCount] = useState(0);
  const [model, setModel] = useState({
    loading: true,
    data: [],
  });
  const [phoneBook, setPhoneBook] = useState(null);
  const [modalPhoneBook, setModalPhoneBook] = useState({
    visible: false,
    data: null,
    selectedKey: null,
  });
  const [countInsertPB, setCountInsertPB] = useState(1);
  const [dataPhoneType, setDataPhoneType] = useState(false);
  const [formRef] = Form.useForm();
  const [formRefPB] = Form.useForm();
  const [activeTab, setActiveTab] = useState("TH");

  const fetchData = () => {
    setLoading(true);
    httpClient
      .get("/admin/company", { cancelToken: source.token })
      .then((res) => {
        formRef?.setFieldsValue({
          ...res.data,
        });
        setLoading(false);
        setData(res.data);
        if (res.data.phone_book) {
          let insertKey = [];
          res.data.phone_book.map((item) =>
            insertKey.push({ ...item, key: item.id })
          );
          setPhoneBook(insertKey);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const loadSubCompany = () => {
    httpClient
      .get(`/admin/company/subCompany`, {
        cancelToken: source.token,
      })
      .then((res) => {
        setModel({
          loading: false,
          data: res.data, //_.orderBy(ret.data.items, (n) => n.name_th),
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const loadPhoneType = () => {
    httpClient
      .get(`/admin/master/phone-type`, {
        cancelToken: source.token,
      })
      .then((res) => {
        setDataPhoneType(res.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const onFinish = (values) => {
    return new Promise((resolve, reject) => {
      const result = { ...values };
      result.phone_book = phoneBook;
      httpClient
        .post("/admin/company", result, { cancelToken: source.token })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        });
    })
      .then(() => {
        Modal.success({
          title: "สำเร็จ",
          content: "บันทึกข้อมูลเรียบร้อย",
          okText: "ปิด",
          onOk() {
            fetchData();
          },
        });
      })
      .catch((reason) => {
        Modal.error({
          title: "ผิดพลาด",
          content: reason,
          okText: "ปิด",
        });
      });
  };

  const handleSyncProject = () => {
    setSyncing(true);
    httpClient
      .post(`/admin/projects/sync`, {}, { cancelToken: source.token })
      .then(() => {
        setSyncing(false);
        loadProjectCount();
      })
      .catch((err) => {
        setSyncing(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const loadProjectCount = () => {
    httpClient
      .get(`/admin/company/project/count`, { cancelToken: source.token })
      .then((res) => {
        setProjectCount(res?.data?.count);
      })
      .catch((err) => {
        setProjectCount(0);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const onFinishFailed = async (errorInfo) => {
    console.error("Form validation failed:", errorInfo);
    message.error(
      `โปรดตรวจสอบ, ${errorInfo.errorFields[0]?.errors[0]} ${
        errorInfo.errorFields[1]?.errors[0]
          ? "และ" + errorInfo.errorFields[1]?.errors[0]
          : ""
      } แล้วลองอีกครั้ง`,
      3
    );

    // Extract the name of the first field with an error
    const firstErrorField = errorInfo.errorFields[0]?.name[0];

    // Determine which tab contains the first error
    if (["company_name_th"].includes(firstErrorField)) {
      setActiveTab("TH");
    } else if (["company_name_en"].includes(firstErrorField)) {
      setActiveTab("EN");
    }
  };

  useEffect(() => {
    if (formRef) {
      fetchData();
      loadProjectCount();
      loadSubCompany();
      loadPhoneType();
    }
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [formRef]);

  useEffect(() => {
    if (modalPhoneBook.visible && formRefPB) {
      formRefPB.resetFields();
      formRefPB.setFieldsValue(modalPhoneBook.data);
    }
  }, [modalPhoneBook]);

  return (
    <Spin spinning={loading} tip="กำลังโหลดข้อมูล">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          padding: "16px 24px 16px 24px",
          border: "1px solid #f0f0f0",
          borderRadius: "6px 6px 0 0",
        }}
      >
        <span>ข้อมูลบริษัท</span>
        <Button
          type="primary"
          style={{ margin: 0 }}
          icon={<SaveOutlined />}
          className="flex justify-center items-center"
          onClick={() => formRef.submit()}
        >
          บันทึก
        </Button>
      </div>
      <Card>
        <Form
          form={formRef}
          onFinish={onFinish}
          wrapperCol={{ md: 18 }}
          labelCol={{ md: 6 }}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={16}>
            <Col span={24} style={{ margin: "auto" }}>
              <Tabs
                activeKey={activeTab}
                onChange={setActiveTab}
                type="card"
                centered
                items={[
                  {
                    key: "TH",
                    label: "ภาษาไทย",
                    forceRender: true,
                    children: (
                      <>
                        <Form.Item
                          label="ชื่อบริษัท (TH)"
                          name="company_name_th"
                          rules={[
                            { required: true, message: "กรุณากรอกชื่อบริษัท" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        {data?.about_th && (
                          <Form.Item
                            label="เกี่ยวกับบริษัท (TH)"
                            name="about_th"
                            getValueProps={(value) => {
                              formRef?.setFieldsValue({
                                html_th: value,
                              });
                            }}
                          >
                            <QuillEditor
                              style={{
                                height: "300px",
                                marginBottom: "50px",
                              }}
                              value={data?.about_th || ""}
                              onChange={(event) => {
                                formRef?.setFieldsValue({
                                  html_th: event,
                                });
                                return event;
                              }}
                            />
                          </Form.Item>
                        )}
                        <Form.Item label="ที่อยู่บริษัท (TH)" name="address_th">
                          <Input.TextArea rows={4} />
                        </Form.Item>
                      </>
                    ),
                  },
                  {
                    key: "EN",
                    label: "ภาษาอังกฤษ",
                    forceRender: true,
                    children: (
                      <>
                        <Form.Item
                          label="ชื่อบริษัท (EN)"
                          name="company_name_en"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกชื่อบริษัท (EN)",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        {data?.about_en && (
                          <Form.Item
                            label="เกี่ยวกับบริษัท (EN)"
                            name="about_en"
                            getValueProps={(value) => {
                              formRef?.setFieldsValue({
                                html_en: value,
                              });
                            }}
                          >
                            <QuillEditor
                              value={data?.about_en || ""}
                              style={{
                                height: "300px",
                                marginBottom: "50px",
                              }}
                              onChange={(event) => {
                                formRef?.setFieldsValue({
                                  html_en: event,
                                });
                                return event;
                              }}
                            />
                          </Form.Item>
                        )}
                        <Form.Item label="ที่อยู่บริษัท (EN)" name="address_en">
                          <Input.TextArea rows={4} />
                        </Form.Item>
                      </>
                    ),
                  },
                ]}
              />
              <Form.Item label="หมายเลขโทรศัพท์" name="tel">
                <Input placeholder="หมายเลขโทรศัพท์" />
              </Form.Item>
              <Form.Item label="แฟกซ์" name="fax">
                <Input placeholder="แฟกซ์" />
              </Form.Item>
              <Form.Item label="อีเมล" name="email">
                <Input placeholder="example@domain.com" />
              </Form.Item>
              <Form.Item label="เว็บไซต์" name="website">
                <Input placeholder="https://google.com" />
              </Form.Item>
              <Form.Item label="Video" name="video_link">
                <Input placeholder="https://youtube.com" />
              </Form.Item>
              <Form.Item label="อีเมลผู้ดูแล" name="contact_email">
                <Input placeholder="admin@domain.com" />
              </Form.Item>
              <Form.Item label="โครงการทั้งหมด">
                <span className="ant-form-text">{`${projectCount} โครงการ`}</span>
                <Form.Item noStyle>
                  <Button
                    type="link"
                    loading={syncing}
                    onClick={handleSyncProject}
                  >
                    Reload
                  </Button>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Card title="ติดต่อ" className="mt-5">
        {phoneBook && (
          <>
            <Table dataSource={phoneBook} pagination={false} size="small">
              <Table.Column title="ชื่อ (TH)" dataIndex="name_th" />
              <Table.Column title="ชื่อ (EN)" dataIndex="name_en" />
              <Table.Column title="โทรศัพท์" dataIndex="telephone" />
              <Table.Column title="Line ID" dataIndex="line_id" />
              <Table.Column title="ประเภท" dataIndex="type_name_th" />
              <Table.Column
                title={
                  <Button
                    icon={<PlusCircleOutlined />}
                    type="link"
                    style={{ margin: 0 }}
                    onClick={() =>
                      setModalPhoneBook({
                        visible: true,
                        data: {
                          name_th: "",
                          name_en: "",
                          telephone: "",
                          line_id: "",
                          type: "",
                        },
                        selectedKey: null,
                      })
                    }
                  >
                    เพิ่ม
                  </Button>
                }
                align="center"
                dataIndex="key"
                render={(text, record) => (
                  <>
                    <Button
                      type="link"
                      style={{ margin: 0 }}
                      onClick={() =>
                        setModalPhoneBook({
                          ...modalPhoneBook,
                          visible: true,
                          data: record,
                          selectedKey: record.id,
                        })
                      }
                      icon={<EditOutlined />}
                    >
                      แก้ไข
                    </Button>
                    <Button
                      type="link"
                      style={{ margin: 0 }}
                      onClick={() => {
                        Modal.confirm({
                          title: "ยืนยันการทำรายการ",
                          content: "คุณต้องการลบข้อมูลผู้ติดต่อนี้หรือไม่",
                          okText: "ลบ",
                          cancelText: "ยกเลิก",
                          onOk() {
                            return new Promise((resolve, reject) => {
                              try {
                                let newPhoneBook = [...phoneBook];
                                let findIndex = phoneBook.findIndex(
                                  (item) => item.id === record.id
                                );
                                if (findIndex >= 0) {
                                  newPhoneBook.splice(findIndex, 1);
                                } else {
                                  console.log(
                                    `Can't remove  (id: ${record.id} )`
                                  );
                                }
                                setPhoneBook(newPhoneBook);
                                resolve();
                              } catch (error) {
                                reject(error.message);
                              }
                            })
                              .then(() => {
                                Modal.success({
                                  title: "สำเร็จ",
                                  content: "ลบข้อมูลผู้ติดต่อเรียบร้อย",
                                  okText: "ปิด",
                                });
                              })
                              .catch((reason) => {
                                Modal.error({
                                  title: "ผิดพลาด",
                                  content: reason,
                                  okText: "ปิด",
                                });
                              });
                          },
                        });
                      }}
                      icon={<DeleteOutlined />}
                    >
                      ลบ
                    </Button>
                  </>
                )}
              />
            </Table>
            <Modal
              open={modalPhoneBook.visible}
              title={
                modalPhoneBook.selectedKey
                  ? modalPhoneBook?.data?.name_th
                  : "เพิ่มเบอร์ติดต่อ"
              }
              okText="เพิ่ม"
              cancelText="ยกเลิก"
              onCancel={() => {
                setModalPhoneBook({
                  ...modalPhoneBook,
                  visible: false,
                  data: null,
                  selectedKey: null,
                });
              }}
              onOk={() => formRefPB?.submit()}
              maskClosable={false}
            >
              <Form
                form={formRefPB}
                labelCol={{ md: 8 }}
                wrapperCol={{ md: 16 }}
                onFinish={(values) => {
                  let index = phoneBook.findIndex(
                    (item) => item.id === modalPhoneBook.selectedKey
                  );
                  if (index >= 0) {
                    let updatePhoneBook = {
                      ...values,
                      key: modalPhoneBook.selectedKey,
                    };
                    let items = [...phoneBook];
                    items[index] = {
                      ...items[index],
                      ...updatePhoneBook,
                    };
                    setPhoneBook(items);
                    setModalPhoneBook({
                      ...modalPhoneBook,
                      visible: false,
                    });
                  } else {
                    let mapPhoneType = dataPhoneType.filter(
                      (item) => item.type_code === values.type
                    );
                    let insertPhoneBook = {
                      ...values,
                      id: "",
                      key: `phonbook-${countInsertPB}`,
                      type_name_th: mapPhoneType[0].name_th,
                    };
                    setPhoneBook([...phoneBook, insertPhoneBook]);

                    setCountInsertPB(countInsertPB + 1);
                    setModalPhoneBook({
                      ...modalPhoneBook,
                      visible: false,
                    });
                  }
                }}
                initialValues={modalPhoneBook.data}
              >
                <Form.Item
                  name="type"
                  label="ประเภท"
                  rules={[{ required: true, message: "กรุณาเลือกประเภท" }]}
                >
                  <Select loading={loading}>
                    <Select.Option value="">-- เลือกประเภท --</Select.Option>
                    {dataPhoneType &&
                      dataPhoneType.map((n, i) => (
                        <Select.Option key={i} value={n.type_code} source={n}>
                          {n[`name_th`]}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="name_th"
                  label="ชื่อ (TH)"
                  rules={[{ required: true, message: "กรุณากรอกชื่อ (TH)" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="name_en"
                  label="ชื่อ (EN)"
                  rules={[{ required: true, message: "กรุณากรอกชื่อ (EN)" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="telephone"
                  label="โทรศัพท์"
                  rules={[
                    {
                      required: true,
                      message: "กรุณากรอกโทรศัพท์",
                      pattern: "^[0-9]+$",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="line_id" label="Line ID">
                  <Input />
                </Form.Item>
              </Form>
            </Modal>
          </>
        )}
      </Card>

      {/* <Card title="บริษัทในเครือ" className="mt-5">
        <Table
          loading={model.loading}
          dataSource={model.data}
          rowKey="company_code"
        >
          <Table.Column title="รหัส" dataIndex="company_code" />
          <Table.Column title="ชื่อ (TH)" dataIndex="company_name_th" />
          <Table.Column title="ชื่อ (EN)" dataIndex="company_name_en" />
          <Table.Column
            dataIndex="company_code"
            align="center"
            render={(text, record) => (
              <Link to={`/setting/subcompany/${record.company_code}`}>
                <Button type="link" style={{ margin: 0 }}>
                  แก้ไข
                </Button>
              </Link>
            )}
          />
        </Table>
      </Card> */}
    </Spin>
  );
};

export default CompanyProfile;
