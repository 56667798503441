import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";
import Crm from "./CRM";

const Dashboard = ({ match }) => (
  <Switch>
    {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/main`}/> */}
    <Route path={`${match.path}/main`} component={Crm} />
    {/* <Route
      path={`${match.path}/crypto`}
      component={asyncComponent(() => import("./Crypto/index"))}
    />
    <Route
      path={`${match.path}/listing`}
      component={asyncComponent(() => import("./Listing/index"))}
    /> */}
  </Switch>
);

export default Dashboard;
