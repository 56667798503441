import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FormatMoment from "../../../MyComponents/FormatMoment";
import ImageError from "../../../MyComponents/ImageError";
import { httpClient } from "../../../util/Api";
import moment from "moment";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

const ListAnnouncement = () => {
  const source = axios.CancelToken.source();
  const history = useHistory();

  const status = "active";
  const [currPage, setCurrPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [list, setList] = useState({
    loading: false,
    data: null,
    total: 0,
  });

  const [projects, setProjects] = useState(null);
  const [projectID, setProjectID] = useState("");
  const [visible, setVisible] = useState(false);
  const [projectSelect, setProjectSelect] = useState(null);
  const [addStatus, setAddStatus] = useState("");
  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setCurrPage(current);
    setLimit(pageSize);
  };

  const fetchProjects = () => {
    httpClient
      .get("/admin/projects", {
        //params: { status: "active" },
        cancelToken: source.token,
      })
      .then((res) => {
        setProjects(res.data.items.sort((a, b) => a.project_id - b.project_id));
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const fetchData = () => {
    setList({ loading: true, data: null });
    httpClient
      .get(`/admin/post`, {
        params: {
          type: "transferInfo",
          status: addStatus,
          query,
          project_id: projectID?.toString(),
          limit,
          offset: (currPage - 1) * limit,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setList({
          loading: false,
          data: res.data?.items,
          total: res.data?.total,
        });
      })
      .catch((err) => {
        setList({ loading: false, data: null, total: 0 });
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const handleClickDelete = (id) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการลบข้อมูลหรือไม่",
      okText: "ลบ",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          httpClient
            .post(
              `/admin/post/${id}/status`,
              { status: "delete" },
              { cancelToken: source.token }
            )
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err?.response?.data ? err?.response?.data : err?.message || err
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ลบข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                fetchData();
              },
            });
          })
          .catch((err) => {
            Modal.error({
              title: "ผิดพลาด",
              content: err,
              okText: "ปิด",
            });
          });
      },
    });
  };

  const handleClickStatus = (text, record) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการเปลี่ยนข้อมูลหรือไม่",
      okText: "ยืนยัน",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          httpClient
            .post(
              `/admin/post/${record.id}/status`,
              { status: text },
              { cancelToken: source.token }
            )
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err?.response?.data ? err?.response?.data : err?.message || err
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "แก้ไขข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                fetchData();
              },
            });
          })
          .catch((err) => {
            Modal.error({
              title: "ผิดพลาด",
              content: err,
              okText: "ปิด",
            });
          });
      },
    });
  };

  // useEffect(() => {
  //   fetchData();
  //   return () => {
  //     source.cancel("Operation canceled by the user.");
  //   };
  // }, [currPage, projectID, addStatus, limit]);

  useEffect(() => {
    if (currPage === 1) {
      fetchData();
    } else {
      setCurrPage(1);
    }
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [query, currPage, projectID, addStatus, limit]);

  useEffect(() => {
    fetchProjects();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, []);

  return (
    <Card
      title="ข้อมูลโอนกรรมสิทธิ์"
      extra={
        <Button
          type="primary"
          style={{ margin: 0 }}
          onClick={() => history.push("/transferinfo/create")}
          icon={<PlusCircleOutlined />}
        >
          เพิ่ม
        </Button>
      }
    >
      <Form layout="inline">
        <Form.Item label="คำค้น">
          <Input.Search
            type="text"
            onSearch={(value) => setQuery(value || "")}
            allowClear
            placeholder="หัวข้อ"
          />
        </Form.Item>
        <Form.Item label="โครงการ">
          <Select
            style={{ width: 150 }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={projectID}
            showSearch
            popupMatchSelectWidth={false}
            onChange={(event) => setProjectID(event)}
            allowClear
          >
            <Select.Option value={""}>ทั้งหมด</Select.Option>
            {projects?.map((n, i) => (
              <Select.Option key={i} value={n.project_id}>
                {`${n.project_id} - ${n.name_th}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="สถานะ">
          <Select
            value={addStatus}
            style={{ width: 150 }}
            onChange={(event) => setAddStatus(event)}
            allowClear
          >
            <Select.Option value={""}>ทั้งหมด</Select.Option>
            <Select.Option value={"active"}>เปิดใช้งาน</Select.Option>
            <Select.Option value={"inactive"}>ปิดใช้งาน</Select.Option>
          </Select>
        </Form.Item>
      </Form>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            loading={list.loading}
            dataSource={list.data ? list.data : []}
            rowKey="id"
            pagination={{
              size: "default",
              total: list.total || 0,
              current: currPage,
              pageSize: limit,
              showLessItems: true,
              showSizeChanger: true,
              //onShowSizeChange: { onShowSizeChange },
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total}`,
              onChange: (page, pageSize) => onShowSizeChange(page, pageSize),
            }}
          >
            <Table.Column
              title="ลำดับ"
              dataIndex="id"
              sorter={(a, b) => a.id - b.id}
            />
            <Table.Column title="หัวข้อ (TH)" dataIndex="title_th" />
            <Table.Column title="หัวข้อ (EN)" dataIndex="title_en" />
            <Table.Column
              title={`ช่วงเวลาการแสดง (วัน/เดือน/ปี)`}
              align="center"
              render={({ start_date, end_date, display_status }) => (
                <div style={{ whiteSpace: "nowrap" }}>
                  <label>
                    {`วันที่เริ่มต้น: ${moment(start_date).format(
                      "DD/MM/YYYY"
                    )}`}
                  </label>
                  <br />
                  <label>
                    {`วันที่สิ้นสุด: ${moment(end_date).format("DD/MM/YYYY")}`}
                  </label>
                  <br />
                  <br />
                  {display_status === "show" ? (
                    <Tag color="success">กำลังแสดง</Tag>
                  ) : (
                    <Tag>หมดเวลาแสดง</Tag>
                  )}
                </div>
              )}
            />
            <Table.Column
              title="แก้ไขล่าสุด"
              dataIndex="update_date"
              align="center"
              className="whitespace-nowrap"
              render={(text) => <FormatMoment text={text} />}
              sorter={(a, b) => a.update_date < b.update_date}
            />
            <Table.Column
              title="สถานะ"
              dataIndex="status"
              align="center"
              width={"15%"}
              render={(text, record) => {
                let status = text?.toLowerCase();
                return (
                  // <Tag
                  //   color={status === "active" ? "success" : "default"}
                  //   className="cursor-pointer"
                  //   onClick={() => {
                  //     setVisible(true);
                  //     setProjectSelect(record);
                  //   }}
                  // >
                  //   {status === "active" ? "เปิดใช้งาน" : "ปิดใช้งาน"}
                  // </Tag>
                  <Switch
                    checkedChildren={`เปิดใช้งาน`}
                    unCheckedChildren={`ปิดใช้งาน`}
                    defaultChecked={status === "active" ? true : false}
                    onClick={(item) => {
                      handleClickStatus(
                        item === true ? "active" : "inactive",
                        record
                      );
                    }}
                  />
                );
              }}
            />
            <Table.Column
              title="จัดการ"
              dataIndex="id"
              align="center"
              className="whitespace-nowrap"
              render={(text) => (
                <div style={{ whiteSpace: "nowrap" }}>
                  <Link to={`/transferinfo/detail/${text}`}>
                    <Button
                      style={{ margin: 0 }}
                      type="link"
                      size="small"
                      icon={<EditOutlined />}
                    >
                      แก้ไข
                    </Button>
                  </Link>
                  <Button
                    style={{ margin: 0 }}
                    type="link"
                    onClick={() => handleClickDelete(text)}
                    icon={<DeleteOutlined />}
                  >
                    ลบ
                  </Button>
                </div>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

export default ListAnnouncement;
