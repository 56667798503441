import React from "react";
import { Route, Switch } from "react-router-dom";

import ListProject from "./ListProject";
import asyncComponent from "../../util/asyncComponent";
const Projects = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListProject} />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() => import("./SingleProject"))}
      />
      <Route
        path={`${match.path}/models/:project_id/:model_id`}
        component={asyncComponent(() => import("./ModelDetail"))}
      />
    </Switch>
  );
};

export default Projects;
