import React from "react";

import { Button, Card, Col, Divider, Popconfirm, Row, List } from "antd";

import ImgCard from "./ImgCard";

import { DeleteOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

const RenderList = ({ dataSource = [], loading = false, onDelete, onSort }) => {
  const handleOnSort = (type, index) => {
    let data = [...dataSource];
    let from = dataSource[index];
    let to = dataSource[type === "prev" ? index - 1 : index + 1];

    data[index] = to;
    data[type === "prev" ? index - 1 : index + 1] = from;
    onSort(data[0].group_key, data);
  };

  const stripTags = (html) => {
    var div = document.createElement("div");
    div.innerHTML = html;
    var text = div.textContent || div.innerText || "";
    return text;
  };

  return (
    <List
      loading={loading}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 5,
      }}
      dataSource={dataSource}
      renderItem={(item, index) => (
        <List.Item>
          <ImgCard
            link_to={`/${
              ["news", "announcement", "lifestyle"].includes(item.type)
                ? item.type
                : `${item.type}s`
            }/detail/${item.ref_id}`}
            loading={loading}
            img_url={item.cover_image.url}
            bodyStyle={{ padding: 8 }}
          >
            <Card.Meta
              style={{ margin: "5px" }}
              title={item.title_th}
              description={
                <div
                  style={{
                    height: "55px",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {stripTags(item.html_th)}
                </div>
              }
            />
            <Divider dashed />
            <Row
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Col span={6}>{`#${index + 1}`}</Col>
              <Col span={6}>
                <Button
                  style={{ margin: "0" }}
                  size="large"
                  type="text"
                  disabled={index === 0}
                  onClick={() => handleOnSort("prev", index)}
                >
                  <LeftOutlined />
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  style={{ margin: "0" }}
                  size="large"
                  type="text"
                  disabled={index + 1 === dataSource.length}
                  onClick={() => handleOnSort("next", index)}
                >
                  <RightOutlined />
                </Button>
              </Col>
              <Col span={6}>  
                <Popconfirm
                  title="คุณต้องการยกเลิกหรือไม่"
                  okText="ใช่"
                  cancelText="ไม่ใช่"
                  onConfirm={() => onDelete(item)}
                >
                  <Button style={{ margin: "0" }} size="large" type="text">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </ImgCard>
        </List.Item>
      )}
    />
  );
};

export default RenderList;
