import React from "react";
import { Avatar, Popover } from "antd";
import { useAuth } from "../../authentication";
import { useHistory } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";

const UserProfile = () => {
  const { userSignOut, authUser } = useAuth();
  const history = useHistory();

  const onLogoutClick = () => {
    userSignOut(() => {
      history.push("/");
    });
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/* <li>My Account</li>
      <li>Connections</li> */}
      <li onClick={onLogoutClick}>Logout</li>
    </ul>
  );

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          //src="https://via.placeholder.com/150x150"
          size={"large"}
          style={{ backgroundColor: getRandomColor(), verticalAlign: "middle" }}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
          icon={<UserOutlined />}
        >
          {authUser.first_name && authUser.first_name.charAt(0).toUpperCase()}
          {authUser.last_name && authUser.last_name.charAt(0).toUpperCase()}
        </Avatar>
        <span className="gx-avatar-name">
          {authUser.first_name} {authUser.last_name}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
