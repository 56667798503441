const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
  {
    languageId: "thailand",
    locale: "th",
    name: "ภาษาไทย",
    icon: "th",
  },
];
export default languageData;
