import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FormatMoment from "../../../MyComponents/FormatMoment";
import ImageError from "../../../MyComponents/ImageError";
import { httpClient } from "../../../util/Api";

const ListAnnouncement = () => {
  const source = axios.CancelToken.source();
  const history = useHistory();

  const limit = 10;
  const status = "active";
  const [currPage, setCurrPage] = useState(1);
  const [query, setQuery] = useState("");
  const [list, setList] = useState({
    loading: false,
    data: null,
    total: 0,
  });

  const [projects, setProjects] = useState(null);
  const [projectID, setProjectID] = useState("");

  const fetchProjects = () => {
    httpClient
      .get("/admin/projects", {
        params: { status: "active" },
        cancelToken: source.token,
      })
      .then((res) => {
        setProjects(res.data.items.sort((a, b) => a.project_id - b.project_id));
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const fetchData = () => {
    setList({ loading: true, data: null });
    httpClient
      .get(`/admin/post`, {
        params: {
          type: "announcement",
          status,
          query,
          project_id: projectID,
          limit,
          offset: (currPage - 1) * limit,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setList({
          loading: false,
          data: res.data?.items,
          total: res.data?.total,
        });
      })
      .catch((err) => {
        setList({ loading: false, data: null, total: 0 });
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const handleClickDelete = (id) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการลบข้อมูลหรือไม่",
      okText: "ลบ",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          httpClient
            .post(
              `/admin/post/${id}/status`,
              { status: "inactive" },
              { cancelToken: source.token }
            )
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err?.response?.data ? err?.response?.data : err?.message || err
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "ลบข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                fetchData();
              },
            });
          })
          .catch((err) => {
            Modal.error({
              title: "ผิดพลาด",
              content: err,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [currPage, projectID]);

  useEffect(() => {
    if (currPage === 1) {
      fetchData();
    } else {
      setCurrPage(1);
    }
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [query]);

  useEffect(() => {
    fetchProjects();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, []);

  return (
    <Card
      title="ข่าวโครงการทั้งหมด"
      extra={
        <Button
          type="primary"
          style={{ margin: 0 }}
          onClick={() => history.push("/announcement/detail/0")}
        >
          เพิ่ม
        </Button>
      }
    >
      <Form layout="inline">
        <Form.Item label="คำค้น">
          <Input.Search
            type="text"
            onSearch={(value) => setQuery(value || "")}
            allowClear
            placeholder="หัวข้อ"
          />
        </Form.Item>
        <Form.Item label="โครงการ">
          <Select
            style={{ width: 150 }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={projectID}
            showSearch
            popupMatchSelectWidth={false}
            onChange={(event) => setProjectID(event)}
            allowClear
          >
            <Select.Option value={""}>ทั้งหมด</Select.Option>
            {projects?.map((n, i) => (
              <Select.Option key={i} value={n.project_id}>
                {`${n.project_id} - ${n.name_th}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            loading={list.loading}
            dataSource={list.data ? list.data : []}
            rowKey="id"
            pagination={{
              size: "default",
              total: list.total || 0,
              current: currPage,
              pageSize: limit,
              showLessItems: true,
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total}`,
              onChange: (page) => setCurrPage(page),
            }}
          >
            <Table.Column
              title="รูป"
              dataIndex="cover_image"
              align="center"
              width="120px"
              render={(text, record) => (
                <Link to={`/announcement/detail/${record.id}`}>
                  <ImageError
                    src={`${process.env.REACT_APP_URL_API}/images/${text?.id}?w=250`}
                  />
                </Link>
              )}
            />
            <Table.Column title="หัวข้อ (TH)" dataIndex="title_th" />
            <Table.Column title="หัวข้อ (EN)" dataIndex="title_en" />
            <Table.Column
              title="แก้ไขล่าสุด"
              dataIndex="update_date"
              align="center"
              className="whitespace-nowrap"
              render={(text) => <FormatMoment text={text} />}
            />
            <Table.Column
              dataIndex="id"
              align="center"
              className="whitespace-nowrap"
              render={(text) => (
                <div style={{ whiteSpace: "nowrap" }}>
                  <Link to={`/announcement/detail/${text}`}>
                    <Button style={{ margin: 0 }} type="link" size="small">
                      แก้ไข
                    </Button>
                  </Link>
                  <Button
                    style={{ margin: 0 }}
                    type="link"
                    onClick={() => handleClickDelete(text)}
                  >
                    ลบ
                  </Button>
                </div>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

export default ListAnnouncement;
