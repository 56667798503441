import React, { useEffect, useState } from "react";
import CircularProgress from "../components/CircularProgress";
import Auxiliary from "../util/Auxiliary";
import { useAuth } from "../authentication";
import { useLocation } from "react-router-dom";

const LandingPage = () => {
  const { isLoading, error, userLandingLogin } = useAuth();
  const location = useLocation();

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let userToken = params.get("userToken");
    if (userToken === null) return;
    //console.log(window.atob(userToken));
    userLandingLogin({ data: userToken });
  }, [location]);

  return (
    <div>
      <div className="gx-loader-view gx-loader-position">
        {/* <span>Oops! Unauthorized: Access is denied due to invalid credentials.</span> */}
        <img src="/assets/images/logo_line.png" width={100} alt="loader" />
        <span className="loader-landing">L &nbsp; ading</span>
      </div>
    </div>
  );
};

export default LandingPage;
