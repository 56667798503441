import React from "react";

import { Route, Switch } from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";
import ListCustomerGroup from "./ListCustomerGroup";

const CustomerGroup = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListCustomerGroup} />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() => import("./SingleCustomerGroup"))}
      />
    </Switch>
  );
};

export default CustomerGroup;
