import React from "react";

import { Route, Switch } from "react-router-dom";

import asyncComponent from "../../../util/asyncComponent";
import UserList from "./UserList";

const Users = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={UserList} />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() => import("./UserDetail"))}
      />
    </Switch>
  );
};

export default Users;
