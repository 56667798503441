import React from "react";
import { Route, Switch } from "react-router-dom";

import CardRichMenu from "./CardRichMenu";

const RichMenu = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={CardRichMenu} />
    </Switch>
  );
};

export default RichMenu;
