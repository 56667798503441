import axios from "axios";
import { useHistory } from "react-router-dom";

export const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_URL_API}`, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a response interceptor
httpClient.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const history = useHistory(); // Get the history object

    if (error.response && error.response.status === 401) {
      // Redirect to login page if 401 Unauthorized error
      history.push("/unauthorized"); // Adjust the path according to your application's routing
    }

    return Promise.reject(error);
  }
);
