import React, { useEffect } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { Modal } from "antd";
import { useAuth } from "../authentication";
import axios from "axios";
import { httpClient } from "../util/Api";

const QuillEditor = ({ value, style, onChange }) => {
  const { authUser } = useAuth();
  const theme = "snow";
  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "align",
    "list",
    "indent",
    "size",
    "header",
    "link",
    "image",
    "video",
    "color",
    "background",
    "font",
  ];
  const modules = {
    toolbar: [
      [{ font: [] }, { header: [] }],
      [{ size: [] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike", "clean"],
      [
        { align: [] },
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  const { quill, quillRef } = useQuill({ theme, modules, formats });

  // Insert Image(selected by user) to quill
  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };

  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  const saveToServer = async (file) => {
    const maxFileSize = 4 * 1024 * 1024; // 4MB in bytes
    const allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/tiff",
      "image/svg+xml",
    ];

    // Check file size
    if (file.size > maxFileSize) {
      Modal.error({
        title: "ผิดพลาด!",
        content: "ขนาดรูปที่แนะนำไม่เกิน 4 MB.",
        okText: "ปิด",
      });
      return;
    }

    // Check file type
    if (!allowedFileTypes.includes(file.type)) {
      Modal.error({
        title: "ผิดพลาด!",
        content: "ไฟล์รูปที่รองรับ .jpg, .jpeg, .png, .gif, .bmp, .tiff, .svg",
        okText: "ปิด",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "type",
      JSON.stringify({
        subject: "project-posts",
        product: "customer-portal-web",
        company_id: authUser.company_id,
      })
    );

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    httpClient
      .post(`${process.env.REACT_APP_URL_API}/files`, formData, config)
      .then((res) => {
        //onSuccess(res.data);
        console.log(res.data.url);
        insertToEditor(res.data.url);
      })
      .catch((err) => {
        console.log(err);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          Modal.error({
            title: "ผิดพลาด!",
            content: err?.response?.data
              ? err?.response?.data
              : err?.message || err,
            okText: "ปิด",
          });
          // message.error(
          //   err?.response?.data ? err?.response?.data : err?.message || err
          // );
        }
      });

    // const res = await fetch(`${process.env.REACT_APP_URL_API}/files`, {
    //   method: "POST",
    //   headers: { "content-type": "multipart/form-data" },
    //   formData,
    // });
    // console.log(res.url);
    // insertToEditor(res.url);
  };

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    //input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        onChange(quill.root.innerHTML);
      });
      // Add custom handler for Image Upload
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
    }
  }, [quill]);

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(value);
    }
  }, [quill]);

  return <div ref={quillRef} style={style} />;
};

export default QuillEditor;
