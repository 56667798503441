import React, { useEffect } from "react";

import { Button } from "antd";
import { useHistory, useLocation } from "react-router-dom";

export default function SurveyMobile() {
  const history = useHistory();
  useEffect(() => {}, []);
  const localtion = useLocation().search;
  const fixJobCode = new URLSearchParams(useLocation().search).get(
    "fixJobCode"
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        width: "100%",
        height: "100vh",
        backgroundColor: "#ffffff",
        // padding: "15px 0 15px 0",
        borderRadius: "30px 30px 0 0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "20vh",
        }}
      >
        <label>แบบสอบถาม บริษัท ศุภาลัย </label>
        <label>ความพึงพอใจการให้บริการงานซ่อมบำรุง</label>
        <label style={{ color: "#4F4F4F" }}>บริษัท ศุภาลัย จำกัด (มหาชน)</label>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "30vh",
          backgroundColor: "#F2F2F2",
          padding: "15px",
        }}
      >
        <label>เพื่อเป็นการพัฒนาการให้บริการงานซ่อมบำรุง</label>
        <label>ของบริษัทฯ ให้ดียิ่งขึ้นต่อไป</label>
        <label
          style={{
            backgroundColor: "#EBFAF1",
            border: "1px solid #27AE60",
            borderRadius: "20px",
            padding: "20px",
            margin: "1%",
          }}
        >
          งานซ่อมแซมของท่าน ดำเนินการแล้ว
        </label>

        <label>ใบแจ้งซ่อมเลขที่ : {fixJobCode}</label>
        <label>ความพึงพอใจของท่านที่มีต่อการให้บริการ</label>
        <label>งานซ่อมบำรุงในครั้งนี้</label>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "center",
          height: "40vh",
        }}
      >
        <label style={{ fontSize: "20px" }}>หลักเกณฑ์การให้คะแนน</label>
        <br />
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "25%",
              width: "25%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#27AE60",
                borderRadius: "50%",
                height: "60px",
                width: "60px",
                color: "#ffffff",
              }}
            >
              <label>10-9</label>
            </div>
            <label style={{ whiteSpace: "nowrap" }}>พอใจมาก</label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "25%",
              marginRight: "25%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#6FCF97",
                borderRadius: "50%",
                height: "60px",
                width: "60px",
                color: "#ffffff",
              }}
            >
              <label>8-7</label>
            </div>
            <label style={{ whiteSpace: "nowrap" }}>ค่อนข้างพอใจ</label>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: "1%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "25%",
              marginLeft: "12.5%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FFCF55",
                borderRadius: "50%",
                height: "60px",
                width: "60px",
                color: "#ffffff",
              }}
            >
              <label>6-5</label>
            </div>
            <label style={{ whiteSpace: "nowrap" }}>ปานกลาง</label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "25%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FF932F",
                borderRadius: "50%",
                height: "60px",
                width: "60px",
                color: "#ffffff",
              }}
            >
              <label>4-3</label>
            </div>
            <label style={{ whiteSpace: "nowrap" }}>ไม่พอใจ</label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "25%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#F87777",
                borderRadius: "50%",
                height: "60px",
                width: "60px",
                color: "#ffffff",
              }}
            >
              <label>2-1</label>
            </div>
            <label style={{ whiteSpace: "nowrap" }}>ควรปรับปรุง</label>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{
            backgroundColor: "#E0B87A",
            color: "#ffffff",
            border: "none",
            borderRadius: "20px",
            height: "50px",
            width: "80%",
          }}
          block
          onClick={() => history.push(`/addon/surveydescription${localtion}`)}
        >
          เริ่มทำแบบสอบถาม
        </Button>
      </div>
    </div>
  );
}
