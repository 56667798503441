import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

import ListAnnouncement from "./ListAnnouncement";

const Announcement = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListAnnouncement} />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() =>
          import("../../MyComponents/SingleDetail")
        )}
      />
    </Switch>
  );
};

export default Announcement;
