import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Tabs,
} from "antd";

import { SaveOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import axios from "axios";
import { httpClient } from "../../../util/Api";
import { Link, useHistory, useParams } from "react-router-dom";

const TabPane = Tabs.TabPane;

const SubCompany = () => {
  const source = axios.CancelToken.source();
  const { id } = useParams();

  const [formRef, setFormRef] = useState(null);
  const [syncing, setSyncing] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [projectCount, setProjectCount] = useState(0);

  const [privacy, setPrivacy] = useState(null);
  const [aboutCompany, setAboutCompany] = useState(null);
  const [terms, setTerms] = useState(null);
  const [consent, setConsent] = useState(null);

  const fetchData = () => {
    setLoading(true);

    httpClient
      .get(`/admin/company/subCompany/${id}`, { cancelToken: source.token })
      .then((res) => {
        formRef?.setFieldsValue({
          ...res.data,
        });
        setLoading(false);
        setData(res.data);
        setAboutCompany(res.data?.about);
        setTerms(res.data?.terms);
        setPrivacy(res.data?.privacy);
        setConsent(res.data?.consent);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const onFinish = (values) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทีกข้อมูลหรือไม่?",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          values.about_th = aboutCompany.about_th;
          values.about_en = aboutCompany.about_en;
          values.terms_th = terms.terms_th;
          values.terms_en = terms.terms_en;
          values.privacy_th = privacy.privacy_th;
          values.privacy_en = privacy.privacy_en;
          values.consent_th = consent.consent_th;
          values.consent_en = consent.consent_en;

          httpClient
            .post(`/admin/company/subCompany/${id}`, values, {
              cancelToken: source.token,
            })
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err?.response?.data ? err?.response?.data : err?.message || err
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                fetchData();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    if (formRef) {
      fetchData();
    }
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [formRef]);

  return (
    <Spin spinning={loading} tip="กำลังโหลดข้อมูล">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          padding: "16px 24px 16px 24px",
          border: "1px solid #f0f0f0",
          borderRadius: "6px 6px 0 0",
        }}
      >
        <span>
          {data?.company_code} : {data?.company_name_th} (
          {data?.company_name_en})
        </span>
        <Button
          type="primary"
          style={{ margin: 0 }}
          icon={<SaveOutlined />}
          className="flex justify-center items-center"
          onClick={() => formRef.submit()}
        >
          บันทึก
        </Button>
      </div>
      <Card>
        <Form
          ref={setFormRef}
          onFinish={onFinish}
          wrapperCol={{ md: 18 }}
          labelCol={{ md: 6 }}
        >
          <Row gutter={16}>
            <Col span={14} style={{ margin: "auto" }}>
              <Tabs type="card" centered>
                <TabPane key="th" tab="ภาษาไทย">
                  <Form.Item
                    label="ชื่อบริษัท (TH)"
                    name="company_name_th"
                    rules={[{ required: true, message: "กรุณากรอกชื่อบริษัท" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="ที่อยู่บริษัท (TH)" name="address_th">
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </TabPane>
                <TabPane key="en" tab="ภาษาอังกฤษ">
                  <Form.Item label="ชื่อบริษัท (EN)" name="company_name_en">
                    <Input />
                  </Form.Item>
                  <Form.Item label="ที่อยู่บริษัท (EN)" name="address_en">
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </TabPane>
              </Tabs>
              <Form.Item label="หมายเลขโทรศัพท์" name="tel">
                <Input placeholder="หมายเลขโทรศัพท์" />
              </Form.Item>
              <Form.Item label="แฟกซ์" name="fax">
                <Input placeholder="แฟกซ์" />
              </Form.Item>
              <Form.Item label="อีเมล" name="email">
                <Input placeholder="example@domain.com" />
              </Form.Item>
              <Form.Item label="เว็บไซต์" name="website">
                <Input placeholder="https://google.com" />
              </Form.Item>
              <Form.Item label="Video" name="video_link">
                <Input placeholder="https://youtube.com" />
              </Form.Item>
              <Form.Item label="อีเมลผู้ดูแล" name="contact_email">
                <Input placeholder="admin@domain.com" />
              </Form.Item>
              <Form.Item label="โครงการทั้งหมด">
                <span className="ant-form-text">{`${data.project_count} โครงการ`}</span>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Card title="PDPA" className="mt-5">
        <Tabs tabPosition="left">
          <TabPane tab="เกี่ยวกับบริษัท (About)" key="about">
            <Row>
              <Col span={5}></Col>
              <Col span={14}>
                {aboutCompany && (
                  <Tabs type="card" centered>
                    <TabPane key="th" tab="ภาษาไทย">
                      <ReactQuill
                        style={{
                          height: "525px",
                          marginBottom: "50px",
                        }}
                        value={aboutCompany?.about_th}
                        onChange={(event_about_th) =>
                          setAboutCompany({
                            ...aboutCompany,
                            about_th: event_about_th,
                          })
                        }
                      />
                    </TabPane>
                    <TabPane key="en" tab="ภาษาอังกฤษ">
                      <ReactQuill
                        style={{
                          height: "525px",
                          marginBottom: "50px",
                        }}
                        value={aboutCompany?.about_en}
                        onChange={(event_about_en) =>
                          setAboutCompany({
                            ...aboutCompany,
                            about_en: event_about_en,
                          })
                        }
                      />
                    </TabPane>
                  </Tabs>
                )}
              </Col>
              <Col span={5}></Col>
            </Row>
          </TabPane>
          <TabPane tab="ข้อกำหนดในการใช้บริการ (Terms)" key="terms">
            <Row>
              <Col span={5}></Col>
              <Col span={14}>
                {terms && (
                  <Tabs type="card" centered>
                    <TabPane key="th" tab="ภาษาไทย">
                      <ReactQuill
                        style={{
                          height: "525px",
                          marginBottom: "50px",
                        }}
                        value={terms?.terms_th}
                        onChange={(event) =>
                          setTerms({
                            ...terms,
                            terms_th: event,
                          })
                        }
                      />
                    </TabPane>
                    <TabPane key="en" tab="ภาษาอังกฤษ">
                      <ReactQuill
                        style={{
                          height: "525px",
                          marginBottom: "50px",
                        }}
                        value={terms?.terms_en}
                        onChange={(event) =>
                          setTerms({
                            ...terms,
                            terms_en: event,
                          })
                        }
                      />
                    </TabPane>
                  </Tabs>
                )}
              </Col>
              <Col span={5}></Col>
            </Row>
          </TabPane>
          <TabPane tab="นโยบายความเป็นส่วนตัว (Privacy)" key="privacy">
            <Row>
              <Col span={5}></Col>
              <Col span={14}>
                {privacy && (
                  <Tabs type="card" centered>
                    <TabPane key="th" tab="ภาษาไทย">
                      <ReactQuill
                        style={{
                          height: "525px",
                          marginBottom: "50px",
                        }}
                        value={privacy?.privacy_th}
                        onChange={(event) =>
                          setPrivacy({
                            ...privacy,
                            privacy_th: event,
                          })
                        }
                      />
                    </TabPane>
                    <TabPane key="en" tab="ภาษาอังกฤษ">
                      <ReactQuill
                        style={{
                          height: "525px",
                          marginBottom: "50px",
                        }}
                        value={privacy?.privacy_en}
                        onChange={(event) =>
                          setPrivacy({
                            ...privacy,
                            privacy_en: event,
                          })
                        }
                      />
                    </TabPane>
                  </Tabs>
                )}
              </Col>
              <Col span={5}></Col>
            </Row>
          </TabPane>
          <TabPane tab="ยินยอมเปิดเผยข้อมูล (Consent)" key="consent">
            <Row>
              <Col span={5}></Col>
              <Col span={14}>
                {consent && (
                  <Tabs type="card" centered>
                    <TabPane key="th" tab="ภาษาไทย">
                      <ReactQuill
                        style={{
                          height: "525px",
                          marginBottom: "50px",
                        }}
                        value={consent?.consent_th}
                        onChange={(event) =>
                          setConsent({
                            ...consent,
                            consent_th: event,
                          })
                        }
                      />
                    </TabPane>
                    <TabPane key="en" tab="ภาษาอังกฤษ">
                      <ReactQuill
                        style={{
                          height: "525px",
                          marginBottom: "50px",
                        }}
                        value={consent?.consent_en}
                        onChange={(event) =>
                          setConsent({
                            ...consent,
                            consent_en: event,
                          })
                        }
                      />
                    </TabPane>
                  </Tabs>
                )}
              </Col>
              <Col span={5}></Col>
            </Row>
          </TabPane>
        </Tabs>
      </Card>
    </Spin>
  );
};

export default SubCompany;
