import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./Home";
import Projects from "./Projects";
import Message from "./Message";
import CustomerGroup from "./CustomerGroup";
import News from "./News";
import Banners from "./Banners";
import Announcement from "./Announcement";
import Promotions from "./Promotions";
import Lifestyles from "./Lifestyles";
import Regulations from "./Regulations";
import Customers from "./Customers";
import Setting from "./Setting";
import Dashboard from "./Dashboard";
import Loan from "./Loan";
import Transfer from "./Transfer";
import Knowledge from "./Knowledge";
import Richmenu from "./Richmenu";
import Feedback from "./Feedback";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={Dashboard} />
      <Route path={`${match.url}home`} component={Home} />
      <Route path={`${match.url}projects`} component={Projects} />
      <Route path={`${match.url}message`} component={Message} />
      <Route path={`${match.url}customer-group`} component={CustomerGroup} />
      <Route path={`${match.url}news`} component={News} />
      <Route path={`${match.url}banners`} component={Banners} />
      <Route path={`${match.url}announcement`} component={Announcement} />
      <Route path={`${match.url}promotions`} component={Promotions} />
      <Route path={`${match.url}lifestyle`} component={Lifestyles} />
      <Route path={`${match.url}regulations`} component={Regulations} />
      <Route path={`${match.url}customers`} component={Customers} />
      <Route path={`${match.url}setting`} component={Setting} />
      <Route path={`${match.url}loaninfo`} component={Loan} />
      <Route path={`${match.url}transferinfo`} component={Transfer} />
      <Route path={`${match.url}knowledgeinfo`} component={Knowledge} />
      <Route path={`${match.url}richmenu`} component={Richmenu} />
      <Route path={`${match.url}feedback`} component={Feedback} />
    </Switch>
  </div>
);

export default App;
