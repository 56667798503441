import React from "react";

import { Route, Switch } from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";
import ListPromotions from "./ListPromotions";

const Promotions = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListPromotions} />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() =>
          import("../../MyComponents/SingleDetail")
        )}
      />
    </Switch>
  );
};

export default Promotions;
