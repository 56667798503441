import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import axios from "axios";

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FormatMoment from "../../../../MyComponents/FormatMoment";
import { httpClient } from "../../../../util/Api";

const UserList = () => {
  const source = axios.CancelToken.source();
  const history = useHistory();

  const [status, setStatus] = useState("");
  const limit = 10;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState("");

  const fetchData = () => {
    setLoading(true);
    httpClient
      .get("/admin/users", {
        params: {
          limit: limit,
          offset: (page - 1) * limit,
          status: status,
          query: query,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [page, query, status]);

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          title="ข้อมูลผู้ใช้"
          extra={
            <Button
              type="primary"
              style={{ margin: 0 }}
              onClick={() => history.push(`/setting/users/detail/0`)}
            >
              เพิ่ม
            </Button>
          }
        >
          <Form layout="inline" className="mb-5">
            <Form.Item label="คำค้น">
              <Input.Search
                type="text"
                onSearch={(value) => setQuery(value)}
                placeholder="ชื่อผู้ใช้/อีเมล"
                allowClear
              />
            </Form.Item>
            <Form.Item label="สถานะ">
              <Select value={status} onChange={(e) => setStatus(e)}>
                <Select.Option value="">ทั้งหมด</Select.Option>
                <Select.Option value="active">เปิดใช้งาน</Select.Option>
                <Select.Option value="inactive">ปิดใช้งาน</Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Table
            loading={loading}
            dataSource={data}
            rowKey="row_id"
            pagination={{
              size: "default",
              pageSize: limit,
              total: total,
              showLessItems: true,
              current: page,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total}`,
              onChange: (page) => setPage(page),
            }}
          >
            <Table.Column title="#" align="center" dataIndex="row_id" />
            <Table.Column
              title="ชื่อผู้ใช้"
              render={(text, { first_name, last_name }) =>
                `${first_name} ${last_name}`
              }
            />
            <Table.Column title="อีเมล" dataIndex="email" />
            <Table.Column title="บทบาท" dataIndex="" />
            <Table.Column
              title="สถานะ"
              dataIndex="status"
              align="center"
              render={(text) => {
                let name = "เปิดใช้งาน";
                let color = "success";
                if (text?.toLowerCase() !== "active") {
                  name = "ปิดใช้งาน";
                  color = "error";
                }
                return <Tag color={color}>{name}</Tag>;
              }}
            />
            <Table.Column
              title="แก้ไขล่าสุด"
              dataIndex="update_date"
              align="center"
              className="whitespace-nowrap"
              render={(text) => <FormatMoment text={text} />}
            />
            <Table.Column
              align="center"
              render={(record) => (
                <Link to={`detail/${record.id}`}>
                  <Button type="link" size="small" style={{ margin: 0 }}>
                    แก้ไข
                  </Button>
                </Link>
              )}
            />
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default UserList;
