import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Upload,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FormatMoment from "../../../MyComponents/FormatMoment";
import ImageError from "../../../MyComponents/ImageError";
import { httpClient } from "../../../util/Api";
import moment from "moment";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MailOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const { TextArea } = Input;

const ListAnnouncement = () => {
  const source = axios.CancelToken.source();
  const history = useHistory();

  const status = "success";
  const [currPage, setCurrPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [list, setList] = useState({
    loading: false,
    data: null,
    total: 0,
  });

  const [projects, setProjects] = useState(null);
  const [projectID, setProjectID] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleEmail, setVisibleEmail] = useState(false);
  const [feedbackSelect, setFeedbacktSelect] = useState(null);
  const [addStatus, setAddStatus] = useState("");
  const [formRef] = Form.useForm();
  const [formRefEmail] = Form.useForm();

  const onShowSizeChange = (current, pageSize) => {
    //console.log(current, pageSize);
    setCurrPage(current);
    setLimit(pageSize);
  };

  const fetchProjects = () => {
    httpClient
      .get("/admin/projects", {
        //params: { status: "active" },
        cancelToken: source.token,
      })
      .then((res) => {
        setProjects(res.data.items.sort((a, b) => a.project_id - b.project_id));
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const fetchData = () => {
    setList({ loading: true, data: null });
    httpClient
      .get(`/admin/feedbacks`, {
        params: {
          status: addStatus,
          search: query,
          project_id: projectID?.toString(),
          limit,
          offset: (currPage - 1) * limit,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setList({
          loading: false,
          data: res.data?.items,
          total: res.data?.total,
        });
      })
      .catch((err) => {
        setList({ loading: false, data: null, total: 0 });
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const onFinish = (values) => {
    httpClient
      .post(`/admin/feedbacks/company/email`, values, {
        cancelToken: source.token,
      })
      .then(() => {
        setVisibleEmail(false);
        message.success("อัพเดท Email สำเร็จ");
      })
      .catch((err) => {
        setVisibleEmail(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const fetch = () => {
    httpClient
      .get(`/admin/feedbacks/company/email`, {
        cancelToken: source.token,
      })
      .then((res) => {
        //console.log(res.data);
        let data = res?.data?.email;
        let email = data?.split(",");
        //console.log(email);
        formRefEmail.setFieldValue("email", email);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  useEffect(() => {
    fetch();
  }, [formRefEmail]);

  useEffect(() => {
    fetchData();
  }, [currPage, limit]);

  useEffect(() => {
    if (currPage === 1) {
      fetchData();
    } else {
      setCurrPage(1);
    }
  }, [query, projectID, addStatus]);

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (visible) {
      if (formRef) {
        formRef.setFieldsValue({
          id: feedbackSelect?.row_id,
          title: feedbackSelect?.title,
          description: feedbackSelect?.description,
          customer_name:
            feedbackSelect?.first_name_th ||
            feedbackSelect?.first_name_en +
              " " +
              feedbackSelect?.last_name_th ||
            feedbackSelect?.last_name_en,
          email: feedbackSelect?.description,
          mobile_no: feedbackSelect?.mobile_no,
          project_name:
            feedbackSelect?.project_name_th || feedbackSelect?.project_name_en,
          create_date: moment(feedbackSelect?.created_date).format(
            "DD/MM/YYYY HH:mm"
          ),
          status: feedbackSelect?.status?.toLowerCase(),
          file_list: feedbackSelect?.file_list,
        });
      }
    } else {
      setFeedbacktSelect(null);
    }
  }, [visible, feedbackSelect, formRef]);

  return (
    <>
      <Card
        title="ข้อเสนอแนะ"
        extra={
          <Button
            type="primary"
            style={{ margin: 0 }}
            onClick={() => {
              setVisibleEmail(true);
            }}
            icon={<MailOutlined />}
          >
            {` ตั้งค่า Email`}
          </Button>
        }
      >
        <Form layout="inline">
          <Form.Item label="คำค้น">
            <Input.Search
              type="text"
              onSearch={(value) => setQuery(value || "")}
              allowClear
              placeholder="หัวข้อ"
            />
          </Form.Item>
          <Form.Item label="โครงการ">
            <Select
              style={{ width: 150 }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={projectID}
              showSearch
              popupMatchSelectWidth={false}
              onChange={(event) => setProjectID(event)}
              allowClear
            >
              <Select.Option value={""}>ทั้งหมด</Select.Option>
              {projects?.map((n, i) => (
                <Select.Option key={i} value={n.project_id}>
                  {`${n.project_id} - ${n.name_th}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="สถานะ">
            <Select
              value={addStatus}
              style={{ width: 150 }}
              onChange={(event) => setAddStatus(event)}
              allowClear
            >
              <Select.Option value={""}>ทั้งหมด</Select.Option>
              <Select.Option value={"success"}>สำเร็จ</Select.Option>
              <Select.Option value={"fail"}>ไม่สำเร็จ</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <Row gutter={16}>
          <Col span={24}>
            <Table
              loading={list.loading}
              dataSource={list.data ? list.data : []}
              rowKey="id"
              pagination={{
                size: "default",
                total: list.total || 0,
                current: currPage,
                pageSize: limit,
                showLessItems: true,
                showSizeChanger: true,
                //onShowSizeChange: { onShowSizeChange },
                showTotal: (total, range) =>
                  `${range[0]} - ${range[1]} of ${total}`,
                onChange: (page, pageSize) => onShowSizeChange(page, pageSize),
              }}
            >
              <Table.Column
                title="ลำดับ"
                dataIndex="row_id"
                sorter={(a, b) => a.row_id - b.row_id}
              />
              <Table.Column title="หัวข้อ" dataIndex="title" />
              <Table.Column title="รายละเอียด" dataIndex="description" />
              <Table.Column title="โครงการ" dataIndex="project_name_th" />
              <Table.Column
                title="วันที่"
                dataIndex="created_date"
                align="center"
                className="whitespace-nowrap"
                render={(text) => <FormatMoment text={text} />}
                sorter={(a, b) => a.created_date < b.created_date}
              />
              <Table.Column
                title="สถานะ"
                dataIndex="status"
                align="center"
                render={(text, record) => {
                  let status = text?.toLowerCase();
                  return (
                    <Tag
                      color={status === "success" ? "success" : "error"}
                      className="cursor-pointer"
                    >
                      {status === "success" ? "สำเร็จ" : "ไม่สำเร็จ"}
                    </Tag>
                  );
                }}
              />
              <Table.Column
                title=""
                dataIndex="id"
                align="center"
                className="whitespace-nowrap"
                render={(text, record) => (
                  <div style={{ whiteSpace: "nowrap" }}>
                    <Button
                      style={{ margin: 0 }}
                      type="link"
                      size="small"
                      icon={<EyeOutlined />}
                      onClick={() => {
                        setVisible(true);
                        setFeedbacktSelect(record);
                      }}
                    >
                      {` รายละเอียด`}
                    </Button>
                  </div>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Card>
      <Modal
        open={visible}
        title="รายละเอียดข้อเสนอแนะ"
        okText="ปิด"
        cancelText={false}
        // onOk={() => formRef.submit()}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        maskClosable={false}
        width={"50%"}
      >
        <Form
          form={formRef}
          wrapperCol={{ md: 18 }}
          labelCol={{ md: 6 }}
          //onFinish={onFinish}
          //initialValues={feedbackSelect}
        >
          <Form.Item label="ลำดับ" name="id">
            <Input readOnly />
          </Form.Item>
          <Form.Item label="หัวข้อ" name="title">
            <Input readOnly value={feedbackSelect?.title} />
          </Form.Item>
          <Form.Item label="รายละเอียด" name="description">
            <TextArea rows={4} readOnly />
          </Form.Item>
          <Form.Item label="ชื่อลูกค้า" name="customer_name">
            <Input readOnly />
          </Form.Item>
          <Form.Item label="อีเมล" name="email">
            <Input readOnly />
          </Form.Item>
          <Form.Item label="เบอร์โทร" name="mobile_no">
            <Input readOnly />
          </Form.Item>
          <Form.Item label="โครงการ" name="project_name">
            <Input readOnly />
          </Form.Item>
          <Form.Item label="วันที่" name="create_date">
            <Input readOnly />
          </Form.Item>
          <Form.Item label="สถานะ" name="status">
            <Tag
              color={feedbackSelect?.status === "success" ? "success" : "error"}
              className="cursor-pointer"
            >
              {feedbackSelect?.status === "success" ? "สำเร็จ" : "ไม่สำเร็จ"}
            </Tag>
          </Form.Item>
          <Form.Item label="รูป">
            <Flex gap="middle" wrap>
              <Image.PreviewGroup preview>
                {feedbackSelect?.file_list &&
                  feedbackSelect?.file_list.map((img) => (
                    <Image
                      key={img?.name}
                      style={{ width: "100%", height: "150px" }}
                      src={img?.url}
                    />
                  ))}
              </Image.PreviewGroup>
            </Flex>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={visibleEmail}
        title="ตั้งค่า Email"
        okText="บันทึก"
        cancelText={false}
        onOk={() => formRefEmail.submit()}
        //onOk={() => setVisible(false)}
        onCancel={() => setVisibleEmail(false)}
        maskClosable={false}
      >
        <Form
          form={formRefEmail}
          wrapperCol={{ md: 24 }}
          labelCol={{ flex: "80px" }}
          onFinish={onFinish}
        >
          <Form.Item name="email" valuePropName="emails">
            <ReactMultiEmail
              placeholder="xxxx@xxxx.com"
              onChange={(_emails) => {
                // setEmails(_emails);
              }}
              autoFocus={true}
              getLabel={(email, index, removeEmail) => {
                console.log(email);
                return (
                  <div data-tag key={index}>
                    <div data-tag-item style={{ height: "15px" }}>
                      {email}
                    </div>
                    <span
                      data-tag-handle
                      style={{ height: "15px", color: "red" }}
                      onClick={() => removeEmail(index)}
                    >
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ListAnnouncement;
