import { Fragment, useState, useRef, useEffect } from "react";
import { Form, Input, Typography, Select, Card, Row, Col } from "antd";
import MultiCrops from "react-multi-crops";
import { push } from "connected-react-router";

export default function CardRichMenu() {
  const [form] = Form.useForm();
  const _width = useRef(window.innerWidth);
  const [coordinates, setCoordinates] = useState([]);
  const [src, setSrc] = useState("");
  const [richMenuResult, setRichMenuResult] = useState({
    size: {
      width: 2500,
      height: 1686,
    },
    selected: true,
    name: "ริชเมนู 1",
    chatBarText: "ประกาศ",
    areas: [],
  });

  const preJson = JSON?.stringify(richMenuResult, undefined, 2);

  // console.log(richMenuResult);
  // console.log(coordinates);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader?.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const changeCoordinate = (coordinate, index, coordinates) => {
    setCoordinates(coordinates);
  };
  const deleteCoordinate = (coordinate, index, coordinates) => {
    setCoordinates(coordinates);
  };

  useEffect(() => {
    window.onresize = () => {
      const ratio = window.innerWidth / _width.current;
      // console.log(ratio, coordinates[0]);
      _width.current = window.innerWidth;
      setCoordinates(
        coordinates.map((c) => ({
          ...c,
          x: c.x * ratio,
          y: c.y * ratio,
          width: c.width * ratio,
        }))
      );
    };

    if (src && coordinates?.length > 0) {
      var result = coordinates?.map((item, index) => {
        let type = richMenuResult?.areas[index]?.action?.type;
        return {
          bounds: {
            x: parseInt(item?.x?.toFixed(0)),
            y: parseInt(item?.y?.toFixed(0)),
            width: parseInt((item?.width * 4)?.toFixed(0)),
            height: parseInt((item?.height * 4)?.toFixed(0)),
          },
          action: {
            type: type || "uri",
          },
        };
      });
      setRichMenuResult({ ...richMenuResult, areas: result });
    } else if (src && coordinates?.length === 0) {
      setRichMenuResult({ ...richMenuResult, areas: [] });
    }
  }, [coordinates]);

  // console.log(richMenuResult);

  return (
    <Fragment>
      <Card title="ริชเมนู">
        <Row gutter={40}>
          <Col span={12}>
            <Form form={form} style={{ marginLeft: "20px" }}>
              <Form.Item label="ชื่อ">
                <Input
                  value={richMenuResult?.name}
                  onChange={(e) =>
                    setRichMenuResult({
                      ...richMenuResult,
                      name: e?.target?.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item
                label="รูปภาพ ( 2500px*1686px &
                  2500px*843px )"
              >
                <Input type="file" onChange={onSelectFile} />
              </Form.Item>
              <Form.Item label="ฐาน:กว้าง">
                <Select
                  value={richMenuResult?.size?.width}
                  options={[{ value: "2500", label: "2500" }]}
                />
              </Form.Item>
              <Form.Item label="ฐาน:สูง">
                <Select
                  value={richMenuResult?.size?.height}
                  options={[
                    { value: "1686", label: "1686" },
                    { value: "843", label: "843" },
                  ]}
                />
              </Form.Item>
              <Form.Item label="ชื่อแถบแชท">
                <Input
                  value={richMenuResult?.chatBarText}
                  onChange={(e) =>
                    setRichMenuResult({
                      ...richMenuResult,
                      chatBarText: e?.target?.value,
                    })
                  }
                />
              </Form.Item>
              <hr />
              {src &&
                coordinates?.length > 0 &&
                coordinates?.map((item, index) => {
                  let type = richMenuResult?.areas[index]?.action?.type;

                  let textUrl = richMenuResult?.areas[index]?.action?.uri;
                  let message = richMenuResult?.areas[index]?.action?.text;

                  return (
                    <Fragment key={index}>
                      <label>การดำเนินการ {`${index + 1}`}</label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "end",
                        }}
                      >
                        {/* <Form.Item
                          label={`พื้นที่ x:${item?.x} y:${item?.y} กว้าง:
                          ${(item?.width * 4)?.toFixed(0)} สูง:${(
                            item?.height * 4
                          )?.toFixed(0)}`}
                        /> */}
                        <Form.Item style={{ width: "418px" }} label="ประเภท">
                          <Select
                            defaultValue={"uri"}
                            value={type}
                            options={[
                              {
                                value: "uri",
                                label: "การดำเนินการเกี่ยวกับ URL",
                              },
                              {
                                value: "message",
                                label: "การดำเนินการเกี่ยวกับข้อความ",
                              },
                            ]}
                            onChange={(e) => {
                              let result = [...richMenuResult?.areas];

                              if (type !== e) {
                                result[index].action = {};
                                result[index].action.type = e;
                              } else {
                                result[index].action.type = e;
                              }

                              setRichMenuResult({
                                ...richMenuResult,
                                areas: result,
                              });
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ width: "400px" }}
                          label={type === "uri" ? "URL" : "ข้อความ"}
                        >
                          <Input
                            value={textUrl || message}
                            onChange={(e) => {
                              let result = [...richMenuResult?.areas];
                              if (type === "uri") {
                                result[index].action.uri = e.target.value;
                              } else if (type === "message") {
                                result[index].action.text = e.target.value;
                              }

                              setRichMenuResult({
                                ...richMenuResult,
                                areas: result,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                    </Fragment>
                  );
                })}
            </Form>
          </Col>
          <Col span={12}>
            {src && (
              <Fragment>
                <div>
                  <MultiCrops
                    src={src}
                    width={2500 / 4}
                    coordinates={coordinates}
                    onChange={changeCoordinate}
                    onDelete={deleteCoordinate}
                  />
                </div>
                <canvas />
                <div style={{ marginTop: "-130px" }}>
                  <pre
                    style={{
                      height: "540px",
                      overflow: "scroll",
                      padding: "10px",
                    }}
                  >
                    {preJson}
                  </pre>
                </div>
              </Fragment>
            )}
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
}
