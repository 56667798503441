import moment from "moment";
import React from "react";

const FormatMoment = ({ text }) => {
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      <label>{moment(text).format("DD/MM/YYYY")}</label>
      <br />
      <label>{moment(text).format("HH:mm")}</label>
    </div>
  );
};

export default FormatMoment;
