import { Button, Card, message, Modal, Space, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { httpClient } from "../../../../util/Api";

const RoleList = () => {
  const source = axios.CancelToken.source();
  const history = useHistory();

  const limit = 10;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchData = () => {
    setLoading(true);
    httpClient
      .get("/admin/roles", {
        params: {
          offset: (page - 1) * limit,
          limit: limit,
          query: "",
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setLoading(false);
        setData(res.data.items);
        setTotal(res.data.total);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [page]);

  return (
    <Card
      title="บทบาท"
      extra={
        <Button
          type="primary"
          style={{ margin: 0 }}
          onClick={() => {
            history.push(`/setting/roles/detail/0`);
          }}
        >
          เพิ่ม
        </Button>
      }
    >
      <Table
        loading={loading}
        id="tb_select"
        style={{ textAlign: "center", marginTop: 20 }}
        dataSource={data}
        rowKey="id"
        pagination={{
          size: "default",
          pageSize: limit,
          total: total,
          showLessItems: true,
          current: page,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          onChange: (page) => setPage(page),
          showSizeChanger: false,
        }}
      >
        <Table.Column title="บทบาท" dataIndex="name" key="name" />
        <Table.Column
          title="จำนวนเมนู"
          dataIndex="modules"
          key="modules"
          align="right"
        />
        <Table.Column
          title="จำนวนผู้ใช้"
          dataIndex="users"
          key="users"
          align="right"
        />
        <Table.Column
          title=""
          key="action"
          align="center"
          render={(text, record) => (
            <Space size="middle">
              <Button
                type="link"
                size="small"
                style={{ margin: 0 }}
                onClick={() => {
                  history.push(`detail/${record.id}`);
                }}
              >
                แก้ไข
              </Button>
              <Button
                type="link"
                size="small"
                style={{ margin: 0 }}
                onClick={() =>
                  Modal.confirm({
                    title: "ยืนยันการทำรายการ",
                    content: "คุณต้องการลบข้อมูลหรือไม่?",
                    okText: "ตกลง",
                    cancelText: "ยกเลิก",
                    onOk() {
                      return new Promise((resolve, reject) => {
                        httpClient
                          .post(
                            `/admin/roles/${record.id}/delete`,
                            {},
                            { cancelToken: source.token }
                          )
                          .then((res) => {
                            resolve(res);
                            fetchData();
                          })
                          .catch((err) => {
                            reject(
                              err?.response?.data
                                ? err?.response?.data
                                : err?.message || err
                            );
                          });
                      })
                        .then(() => {
                          Modal.success({
                            title: "สำเร็จ",
                            content: "ลบข้อมูลเรียบร้อย",
                            okText: "ปิด",
                            onOk() {
                              history.push("/setting/roles/main");
                            },
                          });
                        })
                        .catch((reason) => {
                          Modal.error({
                            title: "ผิดพลาด",
                            content: reason,
                            okText: "ปิด",
                          });
                        });
                    },
                  })
                }
              >
                ลบ
              </Button>
            </Space>
          )}
        />
      </Table>
    </Card>
  );
};

export default RoleList;
