import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import axios from "axios";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FormatMoment from "../../../MyComponents/FormatMoment";
import { httpClient } from "../../../util/Api";

const Facility = () => {
  const source = axios.CancelToken.source();
  const [formRef, setFormRef] = useState(null);
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [datalist, setDatalist] = useState({
    group_key: null,
    name_th: null,
    name_en: null,
  });
  const [select, setSelect] = useState([]);
  const limit = 10;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [query, setQuery] = useState("");
  const [idSelected, setIdSelected] = useState(null);
  const [visible, setVisible] = useState(false);

  const LoadSelect = () => {
    setLoading(true);

    httpClient
      .get("/admin/master/facility/group", {
        params: {},
        cancelToken: source.token,
      })
      .then((res) => {
        setLoading(false);
        setSelect(res.data);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const fetchData = () => {
    setLoading(true);
    httpClient
      .get("/admin/master/facility", {
        params: {
          offset: (page - 1) * limit,
          limit: limit,
          query: query,
          group_key: status,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setLoading(false);
        setData(res.data.items);
        setTotal(res.data.total);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const onFinish = (values) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการบันทึกข้อมูลหรือไม่?",
      okText: "ตกลง",
      cancelText: "ยกเลิก",
      onOk() {
        return new Promise((resolve, reject) => {
          httpClient
            .post(
              idSelected !== null
                ? "/admin/master/facility/" + idSelected
                : "/admin/master/facility",
              {
                name_th: values.name_th,
                name_en: values.name_en,
                group_key: values.group_key,
              },
              {
                cancelToken: source.token,
              }
            )
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(
                err?.response?.data ? err?.response?.data : err?.message || err
              );
            });
        })
          .then(() => {
            Modal.success({
              title: "สำเร็จ",
              content: "บันทึกข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                setVisible(false);
                setIdSelected(null);
                history.push("/setting/facility/main");
                fetchData();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    if (visible && formRef) {
      formRef.resetFields();
    }
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [datalist, query, status, page]);

  useEffect(() => {
    LoadSelect();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Card
            title="สิ่งอำนวยความสะดวก"
            extra={
              <Button
                type="primary"
                style={{ margin: 0 }}
                onClick={() => {
                  if (
                    !(
                      datalist.group_key === null &&
                      datalist.name_th === null &&
                      datalist.name_en === null
                    )
                  ) {
                    setDatalist({
                      group_key: null,
                      name_th: null,
                      name_en: null,
                    });
                  }

                  setVisible(true);
                  setIdSelected(null);
                }}
              >
                เพิ่ม
              </Button>
            }
          >
            <Form layout="inline" className="mb-5">
              <Form.Item label="คำค้น">
                <Input.Search
                  type="text"
                  onSearch={(value) => setQuery(value)}
                  placeholder="ค้นหา"
                  allowClear
                />
              </Form.Item>
              <Form.Item label="ประเภท">
                <Select
                  placeholder="เลือกประเภท"
                  style={{ width: 200 }}
                  value={status}
                  onChange={(event) => {
                    setStatus(event);
                    setPage(1);
                  }}
                >
                  <Select.Option value="">ทั้งหมด</Select.Option>
                  {select.map((item, index) => (
                    <Select.Option
                      key={index}
                      value={item.group_key.toString()}
                    >
                      {item.group_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
            <Table
              loading={loading}
              dataSource={data}
              rowKey="row_id"
              pagination={{
                size: "default",
                pageSize: limit,
                total: total,
                showLessItems: true,
                current: page,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total}`,
                onChange: (page) => setPage(page),
                showSizeChanger: false,
              }}
            >
              <Table.Column
                title="ชื่อ (TH)"
                dataIndex="name_th"
                key="name_th"
              />
              <Table.Column
                title="ชื่อ (EN)"
                dataIndex="name_en"
                key="name_en"
              />
              <Table.Column
                title="ประเภท"
                dataIndex="group_name"
                key="group_name"
              />
              <Table.Column
                title="แก้ไขล่าสุด"
                dataIndex="update_date"
                key="update_date"
                align="center"
                render={(text) => <FormatMoment text={text} />}
              />
              <Table.Column
                title=""
                key="action"
                align="center"
                render={(text, record) => (
                  <Space size="middle">
                    <Button
                      type="link"
                      size="small"
                      style={{ margin: 0 }}
                      onClick={() => {
                        setVisible(true);
                        setIdSelected(record.id);
                        setDatalist({
                          name_th: record.name_th,
                          name_en: record.name_en,
                          group_key: record.group_key,
                        });
                      }}
                    >
                      แก้ไข
                    </Button>
                    <Button
                      type="link"
                      size="small"
                      style={{ margin: 0 }}
                      onClick={() =>
                        Modal.confirm({
                          title: "ยืนยันการทำรายการ",
                          content: "คุณต้องการลบข้อมูลหรือไม่?",
                          okText: "ตกลง",
                          cancelText: "ยกเลิก",
                          onOk() {
                            return new Promise((resolve, reject) => {
                              httpClient
                                .post(
                                  `/admin/master/facility/${record.id}`,
                                  {
                                    status: "inactive",
                                  },
                                  {
                                    cancelToken: source.token,
                                  }
                                )
                                .then((res) => {
                                  resolve(res);
                                })
                                .catch((err) => {
                                  reject(
                                    err?.response?.data
                                      ? err?.response?.data
                                      : err?.message || err
                                  );
                                });
                            })
                              .then(() => {
                                Modal.success({
                                  title: "สำเร็จ",
                                  content: "ลบข้อมูลเรียบร้อย",
                                  okText: "ปิด",
                                  onOk() {
                                    history.push("/setting/facility/main");
                                    fetchData();
                                  },
                                });
                              })
                              .catch((reason) => {
                                Modal.error({
                                  title: "ผิดพลาด",
                                  content: reason,
                                  okText: "ปิด",
                                });
                              });
                          },
                        })
                      }
                    >
                      ลบ
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </Card>
        </Col>
      </Row>
      <Modal
        visible={visible}
        title={idSelected ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        okText="บันทึก"
        cancelText="ยกเลิก"
        onCancel={() => {
          setVisible(false);
          setIdSelected(null);
        }}
        onOk={() => formRef?.submit()}
      >
        <Form
          ref={setFormRef}
          labelCol={{ md: 8 }}
          wrapperCol={{ md: 16 }}
          onFinish={onFinish}
          initialValues={datalist}
        >
          <Form.Item
            name="name_th"
            label="ชื่อ (TH)"
            rules={[{ required: true, message: "กรุณากรอกชื่อ (TH)" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name_en"
            label="ชื่อ (EN)"
            rules={[{ required: true, message: "กรุณากรอกชื่อ (EN)" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="group_key"
            label="ประเภท"
            rules={[{ required: true, message: "กรุณาระบุประเภท" }]}
          >
            <Radio.Group>
              {select.map((det, index) => (
                <Radio
                  key={index}
                  style={{
                    display: "block",
                    height: "30px",
                    lineHeight: "30px",
                  }}
                  value={det.group_key.toString()}
                >
                  {det.group_name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default Facility;
