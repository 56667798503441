import {
  Button,
  Card,
  Form,
  message,
  Modal,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FormatMoment from "../../../MyComponents/FormatMoment";

import { httpClient } from "../../../util/Api";

const { Option } = Select;

const ListMessage = () => {
  const source = axios.CancelToken.source();
  const history = useHistory();
  const [type, setType] = useState("");

  const limit = 10;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchData = () => {
    setLoading(true);

    httpClient
      .get("/admin/message", {
        params: {
          limit: limit,
          offset: (page - 1) * limit,
          type: type,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setData(res.data.items || []);
        setTotal(res.data.total || 0);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [page]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetchData();
    }
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [type]);

  return (
    <Spin spinning={loading} tip="กำลังโหลดข้อมูล">
      <Card
        title="ข้อความ"
        extra={
          <Link to={`/message/detail/0`}>
            <Button type="primary" style={{ margin: 0 }}>
              เพิ่ม
            </Button>
          </Link>
        }
      >
        <Form layout="inline">
          <Form.Item label="ประเภท">
            <Select
              style={{ width: 200 }}
              onChange={(event) => setType(event)}
              defaultValue={""}
              allowClear
            >
              <Option value="">None</Option>
              <Option value="project">โครงการ</Option>
              <Option value="news">ข่าว</Option>
              <Option value="promotion">โปรโมชั่น</Option>
              <Option value="lifestyle">ไลฟ์สไตล์</Option>
              <Option value="webview">Webview</Option>
              <Option value="externalweb">External Web</Option>
              <Option value="announcement">ประกาศจากโครงการ</Option>
            </Select>
          </Form.Item>
        </Form>
        <Table
          loading={loading}
          dataSource={data ? data : []}
          rowKey="row_id"
          pagination={{
            size: "default",
            pageSize: limit,
            total: total,
            showLessItems: true,
            current: page,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            onChange: (page) => {
              setPage(page);
            },
            showSizeChanger: false,
          }}
        >
          <Table.Column title="หัวข้อ" dataIndex="title" key="title" />
          <Table.Column
            title="ข้อความ"
            dataIndex="description"
            key="description"
          />
          <Table.Column
            title="ประเภท"
            dataIndex="target_type"
            key="target_type"
            // align="center"
            render={(text) => (text === null || text === "" ? "None" : text)}
          />
          <Table.Column
            title="วันที่ส่ง"
            dataIndex="last_send_date"
            key="last_send_date"
            align="center"
            render={(text) => text && <FormatMoment text={text} />}
          />
          <Table.Column
            title="แก้ไขล่าสุด"
            dataIndex="update_date"
            key="update_date"
            align="center"
            render={(text) => text && <FormatMoment text={text} />}
          />
          <Table.Column
            title=""
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <Button
                  type="link"
                  size="small"
                  style={{ margin: 0 }}
                  onClick={() =>
                    Modal.confirm({
                      title: "ยืนยันการส่งข้อมูล",
                      content: "คุณต้องการส่งข้อมูลหรือไม่?",
                      okText: "ส่ง",
                      cancelText: "ยกเลิก",
                      onOk() {
                        return new Promise((resolve, reject) => {
                          httpClient
                            .post(`/admin/message/${record.id}/send`)
                            .then((res) => {
                              resolve(res.data);
                            })
                            .catch((err) => {
                              reject(
                                err?.response?.data
                                  ? err?.response?.data
                                  : err?.message || err
                              );
                            });
                        })
                          .then((value) => {
                            Modal.success({
                              title: "สำเร็จ",
                              content: "ส่งข้อมูลเรียบร้อย",
                              okText: "ปิด",
                              onOk() {
                                fetchData();
                              },
                            });
                          })
                          .catch((reason) => {
                            Modal.error({
                              title: "ผิดพลาด",
                              content: reason,
                              okText: "ปิด",
                            });
                          });
                      },
                    })
                  }
                >
                  ส่ง
                </Button>
                <Button
                  type="link"
                  size="small"
                  style={{ margin: 0 }}
                  onClick={() => {
                    history.push(`/message/detail/${record.id}`);
                  }}
                >
                  แก้ไข
                </Button>
                <Button
                  type="link"
                  size="small"
                  style={{ margin: 0 }}
                  onClick={() =>
                    Modal.confirm({
                      title: "ยืนยันการทำรายการ",
                      content: "คุณต้องการลบข้อมูลหรือไม่?",
                      okText: "ตกลง",
                      cancelText: "ยกเลิก",
                      onOk() {
                        return new Promise((resolve, reject) => {
                          httpClient
                            .post(`/admin/message/${record.id}/status`, {
                              status: "inactive",
                            })
                            .then((res) => {
                              resolve(res);
                            })
                            .catch((err) => {
                              reject(
                                err?.response?.data
                                  ? err?.response?.data
                                  : err?.message || err
                              );
                            });
                        })
                          .then((value) => {
                            Modal.success({
                              title: "สำเร็จ",
                              content: "ลบข้อมูลเรียบร้อย",
                              okText: "ปิด",
                              onOk() {
                                fetchData();
                                history.push("/message");
                              },
                            });
                          })
                          .catch((reason) => {
                            Modal.error({
                              title: "ผิดพลาด",
                              content: reason,
                              okText: "ปิด",
                            });
                          });
                      },
                    })
                  }
                >
                  ลบ
                </Button>
              </Space>
            )}
          />
        </Table>
      </Card>
    </Spin>
  );
};

export default ListMessage;
