import React from "react";
import noImg from "../assets/img/NoData.png";

const ImageError = ({ src, height }) => {
  return (
    <>
      {height ? (
        <img
          style={{
            margin: "0px",
            border: "1px solid #f0f0f0",
            height: "250px",
          }}
          alt=""
          src={src}
          onError={(event) => {
            event.target.onerror = null;
            event.target.src = noImg;
          }}
        />
      ) : (
        <img
          style={{
            margin: "0px",
            border: "1px solid #f0f0f0",
          }}
          alt=""
          src={src}
          onError={(event) => {
            event.target.onerror = null;
            event.target.src = noImg;
          }}
        />
      )}
    </>
  );
};

export default ImageError;
