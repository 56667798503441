import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

import ListKnowledge from "./ListKnowledge";

const Knowledge = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListKnowledge} />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() =>
          import("../../MyComponents/SingleDetail2")
        )}
      />
      <Route
        path={`${match.path}/create`}
        component={asyncComponent(() =>
          import("../../MyComponents/SingleDetailCreate")
        )}
      />
    </Switch>
  );
};

export default Knowledge;
