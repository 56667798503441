import React from "react";

import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

import CompanyProfile from "./CompanyProfile";
import Pdpa from "./Pdpa";
import Users from "./Users";
import Roles from "./Roles";
import Facility from "./Facility";
import PrivatePolicy from "./PrivatePolicy";
import TermPolicy from "./TermPolicy";
import CM from "./CM";
import Receipt from "./Receipt";
import SubCompany from "./SubCompany";


const Setting = ({ match }) => {
  return (
    <Switch>
      {/* <Route path={`${match.path}/main`} component={ListRegulations} /> */}
      <Route path={`${match.path}/company`} component={CompanyProfile} />
      <Route path={`${match.path}/pdpa`} component={Pdpa} />
      <Route path={`${match.path}/users`} component={Users} />
      <Route path={`${match.path}/roles`} component={Roles} />
      <Route path={`${match.path}/facility`} component={Facility} />
      <Route path={`${match.path}/privacy`} component={PrivatePolicy} />
      <Route path={`${match.path}/terms`} component={TermPolicy} />
      <Route path={`${match.path}/cm`} component={CM} />
      <Route path={`${match.path}/receipt`} component={Receipt} />
      {/* <Route path={`${match.path}/subcompany`} component={SubCompany} /> */}
      <Route
        path={`${match.path}/subcompany/:id`}
        component={asyncComponent(() => import("./SubCompany"))}
      />
    </Switch>
  );
};

export default Setting;
