import { Button, Form, Input, message, Modal, Radio } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NoScore from "../../assets/img/NoScore.png";
import { httpClient } from "../../util/Api";

const { TextArea } = Input;

const SurveyDescription = () => {
  const source = axios.CancelToken.source();
  const [descript, setDescript] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
  });

  const [modalSuccess, setModalSuccess] = useState(false);

  const fixJobCode = new URLSearchParams(useLocation().search).get(
    "fixJobCode"
  );

  const idFixJobCode = parseInt(fixJobCode?.split("-")[1][0]);
  const [form] = Form.useForm();

  useEffect(() => {}, []);

  const sendData = (value) => {
    httpClient
      .post("/mobile/cm/CMCP06", { data: value }, { cancelToken: source.token })
      .then((res) => {
        setModalSuccess(true);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const onFinish = (values) => {
    let result = {
      fixJobCode: fixJobCode,
      satisfactionDesc: values.satisfactionDesc,
      results: [
        {
          id:
            idFixJobCode === 1 || idFixJobCode === 4
              ? "c05fa363-c884-4704-9b8d-bc479eb74e08"
              : "13632647-6cad-46f7-8dca-4c9ea76ccf7a",
          value: values.one,
          desc: values.descriptOne
            ? values.one > 8
              ? ""
              : values.descriptOne
            : "",
        },
        {
          id:
            idFixJobCode === 1 || idFixJobCode === 4
              ? "e7533c4c-f11a-44a7-bdd4-0bb57d4a91a0"
              : "0f7076f8-7bbc-4146-9cfc-6fae51565d87",
          value: values.two,
          desc: values.descriptTwo
            ? values.two > 8
              ? ""
              : values.descriptTwo
            : "",
        },
        {
          id:
            idFixJobCode === 1 || idFixJobCode === 4
              ? "57004166-62cc-4c3e-be23-8504ecb0237d"
              : "d8fd5a1c-7e7a-47df-a9ca-0d88acd56ce2",
          values: values.three,
          desc: values.descriptThree
            ? values.three > 8
              ? ""
              : values.descriptThree
            : "",
        },
        {
          id:
            idFixJobCode === 1 || idFixJobCode === 4
              ? "707fd4aa-f445-47c0-900a-27b869d00767"
              : "ff6e98b3-9272-463b-80af-f39f971f8622",
          value: values.four,
          desc: values.descriptFour
            ? values.four > 8
              ? ""
              : values.descriptFour
            : "",
        },
        {
          id:
            idFixJobCode === 1 || idFixJobCode === 4
              ? "7b7c247d-a8ea-4b1c-a2c2-648dc6bec98b"
              : "76f0da26-6b2c-4ad1-a86f-d319b3e7d622",
          value: values.five,
          desc: values.descriptFive
            ? values.five > 8
              ? ""
              : values.descriptFive
            : "",
        },
      ],
    };
    sendData(result);
  };

  return (
    <>
      <Modal
        visible={modalSuccess}
        style={{ top: "35%" }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        closable={false}
        footer={[
          <Button
            style={{
              backgroundColor: "#E0B87A",
              color: "#ffffff",
              borderRadius: "10px",
              border: "none",
            }}
            onClick={() => setModalSuccess(false)}
            block
          >
            ตกลง
          </Button>,
        ]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>ทางบริษัท ศุภาลัย จำกัด (มหาชน)</p>
          <p>ขอขอบพระคุณท่าน ที่ให้ความร่วมมือ</p>
          <p>ในการตอบแบบสอบถามในครั้งนี้</p>
        </div>
      </Modal>

      <div
        style={{
          padding: "15px",
          borderRadius: "30px 30px 0 0",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Form onFinish={onFinish} form={form}>
          <p>1. ความสุภาพ กิริยามารยาท การแต่งกาย และการให้บริการ</p>
          <p style={{ color: "#BDBDBD" }}>
            เลือกได้เพียง 1 ข้อ (10 = มากที่สุด - 01 = น้อยที่สุด)
          </p>
          <br />
          <div style={{ padding: "0 15px 0 15px" }}>
            <Form.Item
              name="one"
              // label="1. ความสุภาพ กิริยามารยาท การแต่งกาย และการให้บริการ"
              rules={[{ required: true, message: "*กรุณาให้คะแนน" }]}
            >
              <Radio.Group
                buttonStyle="solid"
                style={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Radio.Button
                  value="10"
                  type="text"
                  className="suyvey-radio-10-9"
                  onClick={() => setDescript({ ...descript, one: false })}
                >
                  10
                </Radio.Button>
                <Radio.Button
                  value="9"
                  className="suyvey-radio-10-9"
                  onClick={() => setDescript({ ...descript, one: false })}
                >
                  09
                </Radio.Button>
                <Radio.Button
                  value="8"
                  className="suyvey-radio-8-7"
                  onClick={() => setDescript({ ...descript, one: true })}
                >
                  08
                </Radio.Button>
                <Radio.Button
                  value="7"
                  className="suyvey-radio-8-7"
                  onClick={() => setDescript({ ...descript, one: true })}
                >
                  07
                </Radio.Button>
                <Radio.Button
                  value="6"
                  className="suyvey-radio-6-5"
                  onClick={() => setDescript({ ...descript, one: true })}
                >
                  06
                </Radio.Button>
                <Radio.Button
                  value="5"
                  className="suyvey-radio-6-5"
                  onClick={() => setDescript({ ...descript, one: true })}
                >
                  05
                </Radio.Button>
                <Radio.Button
                  value="4"
                  className="suyvey-radio-4-3"
                  onClick={() => setDescript({ ...descript, one: true })}
                >
                  04
                </Radio.Button>
                <Radio.Button
                  value="3"
                  className="suyvey-radio-4-3"
                  onClick={() => setDescript({ ...descript, one: true })}
                >
                  03
                </Radio.Button>
                <Radio.Button
                  value="2"
                  className="suyvey-radio-2-1"
                  onClick={() => setDescript({ ...descript, one: true })}
                >
                  02
                </Radio.Button>
                <Radio.Button
                  value="1"
                  className="suyvey-radio-2-1"
                  onClick={() => setDescript({ ...descript, one: true })}
                >
                  01
                </Radio.Button>
                <Radio.Button
                  value="0"
                  className="suyvey-radio"
                  onClick={() => setDescript({ ...descript, one: true })}
                >
                  <img src={NoScore} alt="" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {descript.one && (
              <Form.Item
                name="descriptOne"
                // rules={[{ required: true, message: "Please pick an item!" }]}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="ความคิดเห็นเพิ่มเติม..."
                />
              </Form.Item>
            )}
          </div>

          <hr />

          <p>2. การอธิบายขั้นตอนก่อนและหลังการซ่อม</p>
          <p style={{ color: "#BDBDBD" }}>
            เลือกได้เพียง 1 ข้อ (10 = มากที่สุด - 01 = น้อยที่สุด)
          </p>
          <br />
          <div style={{ padding: "0 15px 0 15px" }}>
            <Form.Item
              name="two"
              // label="1. ความสุภาพ กิริยามารยาท การแต่งกาย และการให้บริการ"
              rules={[{ required: true, message: "*กรุณาให้คะแนน" }]}
            >
              <Radio.Group
                buttonStyle="solid"
                style={{ display: "flex", justifyContent: "left" }}
              >
                <Radio.Button
                  value="10"
                  type="text"
                  className="suyvey-radio-10-9"
                  onClick={() => setDescript({ ...descript, two: false })}
                >
                  10
                </Radio.Button>
                <Radio.Button
                  value="9"
                  className="suyvey-radio-10-9"
                  onClick={() => setDescript({ ...descript, two: false })}
                >
                  09
                </Radio.Button>
                <Radio.Button
                  value="8"
                  className="suyvey-radio-8-7"
                  onClick={() => setDescript({ ...descript, two: true })}
                >
                  08
                </Radio.Button>
                <Radio.Button
                  value="7"
                  className="suyvey-radio-8-7"
                  onClick={() => setDescript({ ...descript, two: true })}
                >
                  07
                </Radio.Button>
                <Radio.Button
                  value="6"
                  className="suyvey-radio-6-5"
                  onClick={() => setDescript({ ...descript, two: true })}
                >
                  06
                </Radio.Button>
                <Radio.Button
                  value="5"
                  className="suyvey-radio-6-5"
                  onClick={() => setDescript({ ...descript, two: true })}
                >
                  05
                </Radio.Button>
                <Radio.Button
                  value="4"
                  className="suyvey-radio-4-3"
                  onClick={() => setDescript({ ...descript, two: true })}
                >
                  04
                </Radio.Button>
                <Radio.Button
                  value="3"
                  className="suyvey-radio-4-3"
                  onClick={() => setDescript({ ...descript, two: true })}
                >
                  03
                </Radio.Button>
                <Radio.Button
                  value="2"
                  className="suyvey-radio-2-1"
                  onClick={() => setDescript({ ...descript, two: true })}
                >
                  02
                </Radio.Button>
                <Radio.Button
                  value="1"
                  className="suyvey-radio-2-1"
                  onClick={() => setDescript({ ...descript, two: true })}
                >
                  01
                </Radio.Button>
                <Radio.Button
                  value="0"
                  className="suyvey-radio"
                  onClick={() => setDescript({ ...descript, two: true })}
                >
                  <img src={NoScore} alt="" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {descript.two && (
              <Form.Item
                name="descriptTwo"
                // rules={[{ required: true, message: "Please pick an item!" }]}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="ความคิดเห็นเพิ่มเติม..."
                />
              </Form.Item>
            )}
          </div>
          <hr />

          <p>
            3. การป้องกันทรัพย์สินไม่ให้เกิดความเสียหายระหว่างซ่อมงาน
            และการทำความสะอาดหลังซ่อมงานเสร็จ
          </p>
          <p style={{ color: "#BDBDBD" }}>
            เลือกได้เพียง 1 ข้อ (10 = มากที่สุด - 01 = น้อยที่สุด)
          </p>
          <br />
          <div style={{ padding: "0 15px 0 15px" }}>
            <Form.Item
              name="three"
              // label="1. ความสุภาพ กิริยามารยาท การแต่งกาย และการให้บริการ"
              rules={[{ required: true, message: "*กรุณาให้คะแนน" }]}
            >
              <Radio.Group
                buttonStyle="solid"
                style={{ display: "flex", justifyContent: "left" }}
              >
                <Radio.Button
                  value="10"
                  type="text"
                  className="suyvey-radio-10-9"
                  onClick={() => setDescript({ ...descript, three: false })}
                >
                  10
                </Radio.Button>
                <Radio.Button
                  value="9"
                  className="suyvey-radio-10-9"
                  onClick={() => setDescript({ ...descript, three: false })}
                >
                  09
                </Radio.Button>
                <Radio.Button
                  value="8"
                  className="suyvey-radio-8-7"
                  onClick={() => setDescript({ ...descript, three: true })}
                >
                  08
                </Radio.Button>
                <Radio.Button
                  value="7"
                  className="suyvey-radio-8-7"
                  onClick={() => setDescript({ ...descript, three: true })}
                >
                  07
                </Radio.Button>
                <Radio.Button
                  value="6"
                  className="suyvey-radio-6-5"
                  onClick={() => setDescript({ ...descript, three: true })}
                >
                  06
                </Radio.Button>
                <Radio.Button
                  value="5"
                  className="suyvey-radio-6-5"
                  onClick={() => setDescript({ ...descript, three: true })}
                >
                  05
                </Radio.Button>
                <Radio.Button
                  value="4"
                  className="suyvey-radio-4-3"
                  onClick={() => setDescript({ ...descript, three: true })}
                >
                  04
                </Radio.Button>
                <Radio.Button
                  value="3"
                  className="suyvey-radio-4-3"
                  onClick={() => setDescript({ ...descript, three: true })}
                >
                  03
                </Radio.Button>
                <Radio.Button
                  value="2"
                  className="suyvey-radio-2-1"
                  onClick={() => setDescript({ ...descript, three: true })}
                >
                  02
                </Radio.Button>
                <Radio.Button
                  value="1"
                  className="suyvey-radio-2-1"
                  onClick={() => setDescript({ ...descript, three: true })}
                >
                  01
                </Radio.Button>
                <Radio.Button
                  value="0"
                  className="suyvey-radio"
                  onClick={() => setDescript({ ...descript, three: true })}
                >
                  <img src={NoScore} alt="" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {descript.three && (
              <Form.Item
                name="descriptThree"
                // rules={[{ required: true, message: "Please pick an item!" }]}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="ความคิดเห็นเพิ่มเติม..."
                />
              </Form.Item>
            )}
          </div>
          <hr />

          <p>
            4. การป้องกันทรัพย์สินไม่ให้เกิดความเสียหายระหว่างซ่อมงาน
            และการทำความสะอาดหลังซ่อมงานเสร็จ
          </p>
          <p style={{ color: "#BDBDBD" }}>
            เลือกได้เพียง 1 ข้อ (10 = มากที่สุด - 01 = น้อยที่สุด)
          </p>
          <br />
          <div style={{ padding: "0 15px 0 15px" }}>
            <Form.Item
              name="four"
              // label="1. ความสุภาพ กิริยามารยาท การแต่งกาย และการให้บริการ"
              rules={[{ required: true, message: "*กรุณาให้คะแนน" }]}
            >
              <Radio.Group
                buttonStyle="solid"
                style={{ display: "flex", justifyContent: "left" }}
              >
                <Radio.Button
                  value="10"
                  type="text"
                  className="suyvey-radio-10-9"
                  onClick={() => setDescript({ ...descript, four: false })}
                >
                  10
                </Radio.Button>
                <Radio.Button
                  value="9"
                  className="suyvey-radio-10-9"
                  onClick={() => setDescript({ ...descript, four: false })}
                >
                  09
                </Radio.Button>
                <Radio.Button
                  value="8"
                  className="suyvey-radio-8-7"
                  onClick={() => setDescript({ ...descript, four: true })}
                >
                  08
                </Radio.Button>
                <Radio.Button
                  value="7"
                  className="suyvey-radio-8-7"
                  onClick={() => setDescript({ ...descript, four: true })}
                >
                  07
                </Radio.Button>
                <Radio.Button
                  value="6"
                  className="suyvey-radio-6-5"
                  onClick={() => setDescript({ ...descript, four: true })}
                >
                  06
                </Radio.Button>
                <Radio.Button
                  value="5"
                  className="suyvey-radio-6-5"
                  onClick={() => setDescript({ ...descript, four: true })}
                >
                  05
                </Radio.Button>
                <Radio.Button
                  value="4"
                  className="suyvey-radio-4-3"
                  onClick={() => setDescript({ ...descript, four: true })}
                >
                  04
                </Radio.Button>
                <Radio.Button
                  value="3"
                  className="suyvey-radio-4-3"
                  onClick={() => setDescript({ ...descript, four: true })}
                >
                  03
                </Radio.Button>
                <Radio.Button
                  value="2"
                  className="suyvey-radio-2-1"
                  onClick={() => setDescript({ ...descript, four: true })}
                >
                  02
                </Radio.Button>
                <Radio.Button
                  value="1"
                  className="suyvey-radio-2-1"
                  onClick={() => setDescript({ ...descript, four: true })}
                >
                  01
                </Radio.Button>
                <Radio.Button
                  value="0"
                  className="suyvey-radio"
                  onClick={() => setDescript({ ...descript, four: true })}
                >
                  <img src={NoScore} alt="" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {descript.four && (
              <Form.Item
                name="descriptFour"
                // rules={[{ required: true, message: "Please pick an item!" }]}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="ความคิดเห็นเพิ่มเติม..."
                />
              </Form.Item>
            )}
          </div>
          <hr />

          <p>5. งานที่ซ่อมเสร็จสามารถใช้งานได้ และมีสภาพเรียบร้อย</p>
          <p style={{ color: "#BDBDBD" }}>
            เลือกได้เพียง 1 ข้อ (10 = มากที่สุด - 01 = น้อยที่สุด)
          </p>
          <br />
          <div style={{ padding: "0 15px 0 15px" }}>
            <Form.Item
              name="five"
              // label="1. ความสุภาพ กิริยามารยาท การแต่งกาย และการให้บริการ"
              rules={[{ required: true, message: "*กรุณาให้คะแนน" }]}
            >
              <Radio.Group
                buttonStyle="solid"
                style={{ display: "flex", justifyContent: "left" }}
              >
                <Radio.Button
                  value="10"
                  type="text"
                  className="suyvey-radio-10-9"
                  onClick={() => setDescript({ ...descript, five: false })}
                >
                  10
                </Radio.Button>
                <Radio.Button
                  value="9"
                  className="suyvey-radio-10-9"
                  onClick={() => setDescript({ ...descript, five: false })}
                >
                  09
                </Radio.Button>
                <Radio.Button
                  value="8"
                  className="suyvey-radio-8-7"
                  onClick={() => setDescript({ ...descript, five: true })}
                >
                  08
                </Radio.Button>
                <Radio.Button
                  value="7"
                  className="suyvey-radio-8-7"
                  onClick={() => setDescript({ ...descript, five: true })}
                >
                  07
                </Radio.Button>
                <Radio.Button
                  value="6"
                  className="suyvey-radio-6-5"
                  onClick={() => setDescript({ ...descript, five: true })}
                >
                  06
                </Radio.Button>
                <Radio.Button
                  value="5"
                  className="suyvey-radio-6-5"
                  onClick={() => setDescript({ ...descript, five: true })}
                >
                  05
                </Radio.Button>
                <Radio.Button
                  value="4"
                  className="suyvey-radio-4-3"
                  onClick={() => setDescript({ ...descript, five: true })}
                >
                  04
                </Radio.Button>
                <Radio.Button
                  value="3"
                  className="suyvey-radio-4-3"
                  onClick={() => setDescript({ ...descript, five: true })}
                >
                  03
                </Radio.Button>
                <Radio.Button
                  value="2"
                  className="suyvey-radio-2-1"
                  onClick={() => setDescript({ ...descript, five: true })}
                >
                  02
                </Radio.Button>
                <Radio.Button
                  value="1"
                  className="suyvey-radio-2-1"
                  onClick={() => setDescript({ ...descript, five: true })}
                >
                  01
                </Radio.Button>
                <Radio.Button
                  value="0"
                  className="suyvey-radio"
                  onClick={() => setDescript({ ...descript, five: true })}
                >
                  <img src={NoScore} alt="" />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {descript.five && (
              <Form.Item
                name="descriptFive"
                // rules={[{ required: true, message: "Please pick an item!" }]}
              >
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder="ความคิดเห็นเพิ่มเติม..."
                />
              </Form.Item>
            )}
          </div>
          <hr />

          <p>6. ข้อเสนอและอื่นๆ เพิ่มเติม</p>
          <br />
          <div style={{ padding: "0 15px 0 15px" }}>
            <Form.Item
              name="satisfactionDesc"
              initialValue=""
              // rules={[{ required: true, message: "Please pick an item!" }]}
            >
              <TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder="ความคิดเห็นเพิ่มเติม..."
              />
            </Form.Item>
          </div>
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <p>*บริษัทฯ ขอให้ความมั่นใจแก่ท่านว่าความเห็นของท่าน</p>
            <p>จะได้รับการเก็บรักษาใว้เป็นความลับ</p>
            <br />
            <Form.Item>
              <Button
                style={{
                  color: "#ffffff",
                  backgroundColor: "#E0B87A",
                  borderRadius: "20px",
                  height: "50px",
                  border: "none",
                  width: "80%",
                }}
                block
                onClick={() => form.submit()}
              >
                ส่งแบบสอบถาม
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default SurveyDescription;
