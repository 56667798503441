import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { httpClient } from "../../../util/Api";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Tag,
} from "antd";

import ImageError from "../../../MyComponents/ImageError";

import axios from "axios";
import FormatMoment from "../../../MyComponents/FormatMoment";
import { EditOutlined } from "@ant-design/icons";

const ListProject = () => {
  const source = axios.CancelToken.source();
  const [limit, setLimit] = useState(10);
  const [currPage, setCurrPage] = useState(1);
  const [query, setQuery] = useState("");
  const [projectType, setProjectType] = useState("");
  const [project, setProject] = useState({
    loading: true,
    data: [],
    total: 0,
  });
  const [visible, setVisible] = useState(false);
  const [projectSelect, setProjectSelect] = useState(null);
  const [status, setStatus] = useState("");

  const [formRef] = Form.useForm();

  const fetchData = () => {
    setProject({
      loading: true,
      data: project.data,
      total: project.total,
    });

    httpClient
      .get("/admin/projects", {
        params: {
          query,
          limit,
          offset: (currPage - 1) * limit,
          type: projectType,
          status,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setProject({
          loading: false,
          data: res.data.items,
          total: res.data.total,
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const onFinish = (values) => {
    httpClient
      .post(`/admin/projects/${projectSelect?.project_id}/status`, values, {
        cancelToken: source.token,
      })
      .then(() => {
        setVisible(false);
        message.success("เปลี่ยนสถานะเรียบร้อย");
      })
      .catch((err) => {
        setVisible(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [currPage, limit]);

  useEffect(() => {
    if (currPage === 1) {
      fetchData();
    } else {
      setCurrPage(1);
    }
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [query, projectType, status]);

  useEffect(() => {
    if (visible) {
      if (formRef) {
        formRef.setFieldsValue({
          status: projectSelect?.status?.toLowerCase(),
        });
      }
    } else {
      fetchData();
      setProjectSelect(null);
    }
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [visible, projectSelect, formRef]);

  return (
    <>
      <Card title="โครงการทั้งหมด">
        <Form layout="inline" className="mb-5">
          <Form.Item label="คำค้น">
            <Input.Search
              type="text"
              onSearch={(value) => setQuery(value || "")}
              allowClear
              placeholder="รหัสโครงการ/ชื่อโครงการ"
            />
          </Form.Item>
          <Form.Item label="ประเภทโครงการ">
            <Select
              style={{ width: 150 }}
              value={projectType}
              allowClear
              onChange={(value) => setProjectType(value || "")}
            >
              <Select.Option value="">ทั้งหมด</Select.Option>
              <Select.Option value="c">แนวสูง</Select.Option>
              <Select.Option value="h">แนวราบ</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="สถานะ">
            <Select
              style={{ width: 150 }}
              value={status}
              allowClear
              onChange={(value) => setStatus(value || "")}
            >
              <Select.Option value="">ทั้งหมด</Select.Option>
              <Select.Option value="active">เปิดใช้งาน</Select.Option>
              <Select.Option value="inactive">ปิดใช้งาน</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <Row gutter={16}>
          <Col span={24}>
            <Table
              rowKey="project_id"
              loading={project.loading}
              dataSource={project.data}
              pagination={{
                size: "default",
                total: project.total ? project.total : 0,
                current: currPage,
                showLessItems: true,
                pageSize: limit,
                showTotal: (total, range) =>
                  `${range[0]} - ${range[1]} of ${total}`,
                onChange: (page, pageSize) => {
                  setCurrPage(page);
                  setLimit(pageSize);
                },
                showSizeChanger: false,
              }}
            >
              <Table.Column
                title="รูป"
                dataIndex="cover_image"
                align="center"
                width="120px"
                render={(text, record) => (
                  <Link to={`detail/${record.project_id}`}>
                    <ImageError src={text.url} />
                  </Link>
                )}
              />
              <Table.Column title="รหัส" dataIndex="project_id" />
              <Table.Column title="ชื่อ (TH)" width="500" dataIndex="name_th" />
              <Table.Column title="ชื่อ (EN)" width="500" dataIndex="name_en" />
              <Table.Column
                title="ประเภท"
                dataIndex="type"
                align="center"
                className="whitespace-nowrap"
                render={(text) =>
                  text?.toLowerCase() === "c"
                    ? "แนวสูง"
                    : text?.toLowerCase() === "h"
                    ? "แนวราบ"
                    : ""
                }
              />
              {/* <Table.Column
                title="สถานะ"
                dataIndex="status"
                align="center"
                render={(text, record) => {
                  let status = text?.toLowerCase();
                  return (
                    <Tag
                      color={status === "active" ? "success" : "default"}
                      className="cursor-pointer"
                      onClick={() => {
                        setVisible(true);
                        setProjectSelect(record);
                      }}
                    >
                      {status === "active" ? "เปิดใช้งาน" : "ปิดใช้งาน"}
                    </Tag>
                  );
                }}
              /> */}
              <Table.Column
                title="แก้ไขล่าสุด"
                dataIndex="update_date"
                align="center"
                className="whitespace-nowrap"
                render={(text) => text && <FormatMoment text={text} />}
              />
              <Table.Column
                dataIndex="project_id"
                align="center"
                render={(text) => (
                  <Link to={`detail/${text}`}>
                    <Button
                      type="link"
                      size="small"
                      style={{ margin: 0 }}
                      icon={<EditOutlined />}
                    >
                      แก้ไข
                    </Button>
                  </Link>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Card>
      <Modal
        open={visible}
        title="เปลี่ยนสถานะโครงการ"
        okText="บันทึก"
        cancelText="ปิด"
        onOk={() => formRef.submit()}
        onCancel={() => setVisible(false)}
        maskClosable={false}
      >
        <Form
          form={formRef}
          wrapperCol={{ md: 18 }}
          labelCol={{ md: 6 }}
          onFinish={onFinish}
        >
          <Form.Item label="รหัส">{projectSelect?.project_id}</Form.Item>
          <Form.Item label="ชื่อ (TH)">{projectSelect?.name_th}</Form.Item>
          <Form.Item label="ชื่อ (EN)">{projectSelect?.name_en}</Form.Item>
          <Form.Item
            label="สถานะ"
            name="status"
            rules={[{ required: true, message: "กรุณาเลือกสถานะ" }]}
          >
            <Radio.Group>
              <Radio value="active">เปิดใช้งาน</Radio>
              <Radio value="inactive">ปิดใช้งาน</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ListProject;
