import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Modal, Row, Spin, Tabs } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../../util/Api";
import QuillEditor from "../../../MyComponents/QuillEditor";

const PrivatePolicy = () => {
  const source = axios.CancelToken.source();
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState(null);

  const fetchData = () => {
    setLoading(true);
    httpClient
      .get("/admin/company/privacy", { cancelToken: source.token })
      .then((res) => {
        setInitialData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const onFinish = () => {
    return new Promise((resolve, reject) => {
      httpClient
        .post("/admin/company/privacy", initialData, {
          cancelToken: source.token,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        });
    })
      .then(() => {
        Modal.success({
          title: "สำเร็จ",
          content: "บันทึกข้อมูลเรียบร้อย",
          okText: "ปิด",
          onOk() {
            fetchData();
          },
        });
      })
      .catch((reason) => {
        Modal.error({
          title: "ผิดพลาด",
          content: reason,
          okText: "ปิด",
        });
      });
  };

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, []);

  return (
    <Spin spinning={loading} tip="กำลังโหลดข้อมูล">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          padding: "16px 24px 16px 24px",
          border: "1px solid #f0f0f0",
          borderRadius: "6px 6px 0 0",
        }}
      >
        <span>นโยบายความเป็นส่วนตัว </span>
        <Button
          type="primary"
          style={{ margin: 0 }}
          icon={<SaveOutlined />}
          onClick={onFinish}
        >
          บันทึก
        </Button>
      </div>
      <Card>
        <Row>
          <Col span={24}>
            {initialData && (
              <Tabs
                type="card"
                centered
                items={[
                  {
                    key: "TH",
                    label: "ภาษาไทย",
                    forceRender: true,
                    children: (
                      <QuillEditor
                        style={{
                          height: "525px",
                          marginBottom: "50px",
                        }}
                        value={initialData?.privacy_th}
                        onChange={(event) => {
                          setInitialData({ ...initialData, privacy_th: event });
                          return event;
                        }}
                      />
                    ),
                  },
                  {
                    key: "EN",
                    label: "ภาษาอังกฤษ",
                    forceRender: true,
                    children: (
                      <QuillEditor
                        style={{
                          height: "525px",
                          marginBottom: "50px",
                        }}
                        value={initialData?.consent_en}
                        onChange={(event) => {
                          setInitialData({ ...initialData, consent_en: event });
                          return event;
                        }}
                      />
                    ),
                  },
                ]}
              />
            )}
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default PrivatePolicy;
