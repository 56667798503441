import React from "react";

import { Route, Switch } from "react-router-dom";

import asyncComponent from "../../util/asyncComponent";
import ListCustomers from "./ListCustomers";

const Customers = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListCustomers} />
      <Route
        path={`${match.path}/units/:id`}
        component={asyncComponent(() => import("./UnitCustomers"))}
      />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() => import("./DetailCustomers"))}
      />
    </Switch>
  );
};

export default Customers;
