import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormatMoment from "../../../MyComponents/FormatMoment";
import { httpClient } from "../../../util/Api";

const ListCustomers = () => {
  const source = axios.CancelToken.source();
  const [status, setStatus] = useState("");

  let limit = 10;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  const fetchData = () => {
    setLoading(true);
    httpClient
      .get("/admin/customers", {
        params: {
          limit: limit,
          offset: (page - 1) * limit,
          status: status,
          query: query,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [page, query, status]);

  return (
    <Card title="ข้อมูลลูกค้า">
      <Form layout="inline">
        <Form.Item label="คำค้น">
          <Input.Search
            type="text"
            onSearch={(value) => {
              setPage(1);
              setQuery(value);
            }}
            placeholder="ชื่อลูกค้า/User Login/เบอร์โทรศัพท์/วันเกิด(วว/ดด/ปปปป)"
            allowClear
          />
        </Form.Item>
        <Form.Item label="สถานะ">
          <Select
            style={{ width: 200 }}
            value={status}
            onChange={(e) => setStatus(e)}
          >
            <Select.Option value="">ทั้งหมด</Select.Option>
            <Select.Option value="verify">ยืนยันตัวตนแล้ว</Select.Option>
            <Select.Option value="unverify">ยังไม่ยืนยันตัวตน</Select.Option>
          </Select>
        </Form.Item>
      </Form>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={data}
            rowKey="row_id"
            pagination={{
              size: "default",
              pageSize: limit,
              total: total,
              showLessItems: true,
              current: page,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total}`,
              onChange: (page) => setPage(page),
              showSizeChanger: false,
            }}
          >
            <Table.Column title="รหัสลูกค้า" dataIndex="rem_member_id" />
            <Table.Column
              title="ชื่อลูกค้า"
              render={(text, { first_name, last_name }) =>
                `${first_name} ${last_name}`
              }
            />

            <Table.Column title="เบอร์โทรศัพท์" dataIndex="mobile" />
            <Table.Column title="อีเมล" dataIndex="email" />
            <Table.Column
              title="วันที่ลงทะเบียน"
              align="center"
              dataIndex="create_date"
              render={(text) => text && <FormatMoment text={text} />}
            />
            <Table.Column
              title="วันที่ยืนยันตัวตน"
              align="center"
              dataIndex="verify_date"
              render={(text) => text && <FormatMoment text={text} />}
            />
            <Table.Column
              dataIndex="id"
              align="center"
              render={(text, { rem_customer_id, citizen_id }) => (
                <div style={{ whiteSpace: "nowrap" }}>
                  <Link
                    to={{
                      pathname: `/customers/units/${text}`,
                      state: {
                        customer_id: rem_customer_id,
                        citizen_id: citizen_id,
                      },
                    }}
                  >
                    <Button style={{ margin: 0 }} type="link" size="small">
                      รายละเอียด
                    </Button>
                  </Link>
                </div>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

export default ListCustomers;
