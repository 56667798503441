import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

import ListNews from "./ListNews";

const News = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListNews} />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() =>
          import("../../MyComponents/SingleDetail")
        )}
      />
    </Switch>
  );
};

export default News;
