import antdTh from "antd/lib/locale/th_TH";
import enMessages from "../locales/th_TH.json";

const ThLang = {
  messages: {
    ...enMessages,
  },
  antd: antdTh,
  locale: "th-TH",
};
export default ThLang;
