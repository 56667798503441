import React from "react";
import { Route, Switch } from "react-router-dom";

import ListFeedback from "./ListFeedback";

const Feedback = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListFeedback} />
    </Switch>
  );
};

export default Feedback;
