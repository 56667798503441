import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

import ListLoan from "./ListLoan";

const Loan = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListLoan} />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() =>
          import("../../MyComponents/SingleDetail2")
        )}
      />
      <Route
        path={`${match.path}/create`}
        component={asyncComponent(() =>
          import("../../MyComponents/SingleDetailCreate")
        )}
      />
    </Switch>
  );
};

export default Loan;
