import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { httpClient } from "../../../util/Api";
import ImageError from "../../../MyComponents/ImageError";
import axios from "axios";
import FormatMoment from "../../../MyComponents/FormatMoment";

const ListBanner = () => {
  const source = axios.CancelToken.source();
  const history = useHistory();
  const limit = 10;
  const status = "active";
  const [currPage, setCurrPage] = useState(1);
  const [query, setQuery] = useState("");
  const [list, setList] = useState({
    loading: false,
    data: null,
    total: 0,
  });

  const fetchData = () => {
    setList({ loading: true, data: null, total: 0 });
    httpClient
      .get(`/admin/post`, {
        params: {
          type: "banner",
          status,
          query,
          limit,
          offset: (currPage - 1) * limit,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setList({
          loading: false,
          data: res.data?.items,
          total: res.data?.total,
        });
      })
      .catch((err) => {
        setList({ loading: false, data: null, total: 0 });
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  const handleClickDelete = (id) => {
    Modal.confirm({
      title: "ยืนยันการทำรายการ",
      content: "คุณต้องการลบข้อมูลหรือไม่",
      okText: "ลบ",
      cancelText: "ปิด",
      onOk() {
        return new Promise((resolve, reject) => {
          httpClient
            .post(
              `/admin/post/${id}/status`,
              { status: "inactive" },
              { cancelToken: source.token }
            )
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(
                err?.response?.data ? err?.response?.data : err?.message || err
              );
            });
        })
          .then((value) => {
            Modal.success({
              title: "สำเร็จ",
              content: "ลบข้อมูลเรียบร้อย",
              okText: "ปิด",
              onOk() {
                fetchData();
              },
            });
          })
          .catch((reason) => {
            Modal.error({
              title: "ผิดพลาด",
              content: reason,
              okText: "ปิด",
            });
          });
      },
    });
  };

  useEffect(() => {
    fetchData();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [currPage]);

  useEffect(() => {
    if (currPage === 1) {
      fetchData();
    } else {
      setCurrPage(1);
    }
  }, [query, status]);

  return (
    <Card
      title="โฆษณาทั้งหมด"
      extra={
        <Button
          type="primary"
          style={{ margin: 0 }}
          onClick={() => history.push("/banners/detail/0")}
        >
          เพิ่ม
        </Button>
      }
    >
      <Form layout="inline" className="mb-5">
        <Form.Item label="คำค้น">
          <Input.Search
            type="text"
            onSearch={(value) => setQuery(value || "")}
            allowClear
            placeholder="หัวข้อ"
          />
        </Form.Item>
      </Form>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            loading={list.loading}
            dataSource={list.data ? list.data : []}
            rowKey="id"
            pagination={{
              size: "default",
              total: list.total || 0,
              current: currPage,
              pageSize: limit,
              showLessItems: true,
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total}`,
              onChange: (page) => setCurrPage(page),
            }}
          >
            <Table.Column
              title="รูป"
              dataIndex="cover_image"
              align="center"
              width="120px"
              render={(text, record) => (
                <Link to={`/banners/detail/${record.id}`}>
                  <ImageError
                    src={`${process.env.REACT_APP_URL_API}/images/${text?.id}?w=250`}
                  />
                </Link>
              )}
            />
            <Table.Column title="หัวข้อ (TH)" dataIndex="title_th" />
            <Table.Column title="หัวข้อ (EN)" dataIndex="title_en" />
            <Table.Column
              title="ช่วงโฆษณา"
              align="center"
              render={({ start_date, end_date, display_status }) => (
                <div style={{ whiteSpace: "nowrap" }}>
                  <label>
                    {`วันที่เริ่มต้น: ${moment(start_date).format(
                      "DD/MM/YYYY"
                    )}`}
                  </label>
                  <br />
                  <label>
                    {`วันที่สิ้นสุด: ${moment(end_date).format("DD/MM/YYYY")}`}
                  </label>
                  <br />
                  <br />
                  {display_status === "show" ? (
                    <Tag color="success">กำลังแสดง</Tag>
                  ) : (
                    <Tag>หมดเวลาแสดง</Tag>
                  )}
                </div>
              )}
            />
            <Table.Column
              title="แก้ไขล่าสุด"
              dataIndex="update_date"
              align="center"
              render={(text) => text && <FormatMoment text={text} />}
            />
            <Table.Column
              dataIndex="id"
              align="center"
              className="whitespace-nowrap"
              render={(text) => (
                <div style={{ whiteSpace: "nowrap" }}>
                  <Link to={`/banners/detail/${text}`}>
                    <Button style={{ margin: 0 }} type="link" size="small">
                      แก้ไข
                    </Button>
                  </Link>
                  <Button
                    style={{ margin: 0 }}
                    type="link"
                    onClick={() => handleClickDelete(text)}
                  >
                    ลบ
                  </Button>
                </div>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

export default ListBanner;
