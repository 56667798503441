import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Table,
  DatePicker,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { httpClient } from "../../../../util/Api";
import FormatMoment from "../../../../MyComponents/FormatMoment";

const { Paragraph, Text } = Typography;

const ReceiptList = () => {
  const source = axios.CancelToken.source();
  const limit = 10;
  const [currPage, setCurrPage] = useState(1);
  const [orderCode, setOrderCode] = useState("");
  const [chargeId, setChargeId] = useState("");
  const [date, setDate] = useState("");

  const [list, setList] = useState({
    loading: false,
    data: null,
    total: 0,
  });

  const LoadList = () => {
    setList({ loading: true, data: null, total: 0 });
    httpClient
      .get(`/admin/payment/all`, {
        params: {
          charge_id: chargeId,
          order_code: orderCode,
          date: date,
          limit,
          offset: (currPage - 1) * limit,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setList({
          loading: false,
          data: res.data?.data,
          total: res.data?.total,
        });
      })
      .catch((err) => {
        setList({ loading: false, data: null, total: 0 });
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else {
          message.error(
            err?.response?.data ? err?.response?.data : err?.message || err
          );
        }
      });
  };

  useEffect(() => {
    LoadList();
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [currPage]);

  useEffect(() => {
    if (currPage === 1) {
      LoadList();
    } else {
      setCurrPage(1);
    }
  }, [orderCode, chargeId, date]);

  const dateFormat = "DD-MM-YYYY";
  return (
    <Card title="ใบเสร็จทั้งหมด">
      <Form layout="inline">
        <Form.Item label="Order Code">
          <Input.Search
            type="text"
            onSearch={(value) => setOrderCode(value || "")}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Charge Id">
          <Input.Search
            type="text"
            onSearch={(value) => setChargeId(value || "")}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Date">
          <Space direction="vertical">
            <DatePicker
              format={dateFormat}
              onChange={(date, dateString) => setDate(dateString || "")}
              allowClear
            />
          </Space>
        </Form.Item>
      </Form>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            loading={list.loading}
            dataSource={list.data ? list.data : []}
            rowKey="id"
            pagination={{
              size: "default",
              total: list.total || 0,
              current: currPage,
              pageSize: limit,
              showLessItems: true,
              showTotal: (total, range) =>
                `${range[0]} - ${range[1]} of ${total}`,
              onChange: (page) => setCurrPage(page),
            }}
          >
            <Table.Column title="Bank" dataIndex="payment_gateway" />
            {/* <Table.Column
              title="Order Code"
              dataIndex="order_code"
              width={10}
              render={(text) => (
                <Tooltip placement="topLeft" title={text}>
                  <Text style={{ width: 200 }} ellipsis={true}>
                    {text}
                  </Text>
                </Tooltip>
              )}
            />
            <Table.Column
              title="Charge Id"
              dataIndex="charge_id"
              width={10}
              render={(text) => (
                <Tooltip placement="topLeft" title={text}>
                  <Text style={{ width: 200 }} ellipsis={true}>
                    {text}
                  </Text>
                </Tooltip>
              )}
            /> */}
            <Table.Column
              title="Charge Id"
              dataIndex="charge_id"
              render={(text, v) => (
                <>
                  {v.charge_id && (
                    <Text style={{ width: 200 }}>
                      <strong>Charge Id 1:</strong> {v.charge_id}
                    </Text>
                  )}
                  {v.qr_code && (
                    <>
                      <br />
                      <br />
                      <Paragraph copyable style={{ width: 200 }}>
                        <strong>QR Code :</strong> {v.qr_code}
                      </Paragraph>
                    </>
                  )}
                </>
              )}
            />
            <Table.Column title="Payment Type" dataIndex="payment_type" />
            <Table.Column title="Term Name" dataIndex="term_name" />
            <Table.Column
              title="Ref"
              dataIndex="ref_1"
              render={(text, v) => (
                <Tooltip placement="topLeft" title={text}>
                  <Text>
                    <strong>Ref 1:</strong> {v.ref_1}
                  </Text>
                  <br />
                  <Text>
                    <strong>Ref 2:</strong> {v.ref_2}
                  </Text>
                  <br />
                  <Text>
                    <strong>Ref 3:</strong> {v.ref_3}
                  </Text>
                </Tooltip>
              )}
            />
            <Table.Column
              title="Amount"
              dataIndex="amount"
              align="right"
              render={(text) => (
                <Text>
                  {text.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </Text>
              )}
            />
            <Table.Column
              title="Create Date"
              dataIndex="create_date"
              align="center"
              sorter={(a, b) => a.create_date.localeCompare(b.create_date)}
              render={(text) => <FormatMoment text={text} />}
            />
            <Table.Column
              title="Status"
              align="center"
              dataIndex="payment_status"
              render={(text) => <Tag color="orange">{text}</Tag>}
            />
            <Table.Column
              dataIndex="id"
              align="center"
              render={(id) => (
                <>
                  <Link to={`/setting/receipt/detail/${id}`}>
                    <Button type="link" size="small" style={{ margin: 0 }}>
                      ออกใบเสร็จ
                    </Button>
                  </Link>
                </>
              )}
            />
          </Table>
        </Col>
      </Row>
    </Card>
  );
};

export default ReceiptList;
