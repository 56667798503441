import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

import ListTransfer from "./ListTransfer";

const Transfer = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListTransfer} />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() =>
          import("../../MyComponents/SingleDetail2")
        )}
      />
      <Route
        path={`${match.path}/create`}
        component={asyncComponent(() =>
          import("../../MyComponents/SingleDetailCreate")
        )}
      />
    </Switch>
  );
};

export default Transfer;
