import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

import ListMessage from "./ListMessage";

const Message = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/main`} component={ListMessage} />
      <Route
        path={`${match.path}/detail/:id`}
        component={asyncComponent(() => import("./SingleMessage"))}
      />
    </Switch>
  );
};

export default Message;
