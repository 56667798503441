import React from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

const Unauthorized = () => {
  const history = useHistory();

  const handleBackHome = () => {
    history.push("/");
  };

  return (
    <Result
      status="403"
      title="401"
      subTitle="Oops! Unauthorized: Access is denied due to invalid credentials."
      extra={
        <Button type="primary" onClick={handleBackHome}>
          Back Home
        </Button>
      }
      style={{ height: "100vh", alignContent: "center" }}
    />
  );
};

export default Unauthorized;
