import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from "../../authentication";

import CustomScrollbars from "../../util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
// import UserProfile from "./UserProfile"; // Uncomment if needed
// import AppsNavigation from "./AppsNavigation"; // Uncomment if needed

import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages"; // Uncomment if needed

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const { pathname } = useSelector(({ common }) => common);
  const { modules } = useAuth();
  const [menuData, setMenuData] = useState([]);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[0] + "/main";
  //console.log(selectedKeys, defaultOpenKeys);
  const setMenuItem = () => {
    const transformedData = [
      {
        key: "main",
        label: "เมนูหลัก",
        type: "group",
        className: "gx-menu-group",
        children: [
          // {
          //   key: "dashboard/main",
          //   label: (
          //     <Link to={`/dashboard/main`}>
          //       <i className={`icon icon-dasbhoard`} />
          //       <span>Dashboard</span>
          //     </Link>
          //   ),
          //   //icon: <i className={`icon icon-dasbhoard`} />,
          // },
        ],
      },
    ];

    modules.forEach((group) => {
      if (group?.parent_id === 0 && !group?.children) {
        transformedData[0].children.push({
          key: group.path + "/main",
          label: (
            <Link to={`/${group.path}/main`}>
              <i className={`icon ${group.icon || "icon-widgets"}`} />
              <span>{group.name}</span>
            </Link>
          ),
          //icon: <i className={`icon ${group.icon || "icon-widgets"}`} />,
        });
      } else if (group?.children) {
        transformedData.push({
          key: group.path + "/main",
          label: group.name,
          type: "group",
          className: "gx-menu-group",
          children: group.children.map((item) => ({
            key: "setting/" + item.path + "/main",
            label: (
              <Link to={`/setting/${item.path}/main`}>
                <i className={`icon ${item.icon || "icon-setting"}`} />
                <span>{item.name}</span>
              </Link>
            ),
            //icon: <i className={`icon ${item.icon || "icon-setting"}`} />,
          })),
        });
      }
    });

    setMenuData(transformedData);
  };

  useEffect(() => {
    setMenuItem();
  }, [modules]);

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            items={menuData}
          />
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
